import './MoleculesList.css'
import { ReactComponent as RemoveSVG } from '../../../../assets/svg/close-circle-outline.svg';
import { ReactComponent as RemoveAllSVG } from '../../../../assets/svg/delete-sweep-outline.svg';
import { ReactComponent as EditSVG } from '../../../../assets/svg/pencil-outline.svg';
import { useCallback, useMemo, useEffect } from 'react';
import { ContainerLayout } from '../../../../components/Container/ContainerLayout';
import { Table } from '../../../../components/Table/Table';
import { Molecule, MoleculeEntry } from '../../../../models/domain/MoleculeEntry';
import { Accordion } from '../../../../components/Accordion/Accordion';
import { IconButton } from '../../../../components/IconButton/IconButton';
import { AccordionRowList } from '../../../../components/Accordion/AccordionRowList';
import { useViewport } from '../../../../Hooks/UseViewPort';
import { ValidationsFields } from '../../ValidationsFields/validationsFields';
import { ReactComponent as HelpSVG } from '../../../../assets/svg/help-circle-outline.svg'
import { SubmissionTableHelpPopUp } from '../../../../components/PopUps/SubmissionTableHelpPopUp';
import { useGlobalPopup } from '../../../../Infrastructure/UI/Hooks/useGlobalPopup';
import { SmilesValidator } from '../SubmissionForm/SmilesValidator';
import { ActionButton } from '../../../../components/ActionButton/ActionButton';
import Tooltip from "@material-ui/core/Tooltip";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

interface IProps{
    isLoading?: boolean;
    moleculesList: MoleculeEntry[];
    onSubmit: () => void;
    onDelete: (molecule: MoleculeEntry) => void;
    onDeleteAll: () => void;
    onEdit: (molecule: MoleculeEntry) => void;
}


export function fieldToString(field: any) {
    if(field === undefined || field === null)
        return "";
    return field;
}


export function MoleculesList(props: IProps){
    const { width } = useViewport();
    const {setShowPopup, setPopupContent} = useGlobalPopup();

    const formIsValid = useMemo(() => {
        const validators = new ValidationsFields();
        if(props.moleculesList.length < 1){ return false }
        
        for(var row of props.moleculesList) {
            const rowValid = (
                   validators.validateMelting(fieldToString(row.meltingPoint))
                && validators.validateEnthalpy(fieldToString(row.enthalpyOfFusion))
                && validators.validateWater(fieldToString(row.waterSolubility))
                && validators.validatePH(fieldToString(row.ph))
                && validators.validateLogP(fieldToString(row.logP))
            );

            if(!rowValid){ return false }
        }
        return true;
    },[props.moleculesList]);


    const validateLineOfList = useCallback((value: MoleculeEntry, numberField: number) => {
        const validators = new ValidationsFields()
        let css = "n4mp-table-cell";console.log(value);

        let numberOfPotentiallyTheSame = props.moleculesList.filter(x => validators.moleculeIsPotentiallyTheSame(x,value))
            if(numberOfPotentiallyTheSame.length && numberOfPotentiallyTheSame.length > 1){
                css = "n4mp-table-cell-potential-duplicated";
            }

            switch (numberField){
                case Molecule.Identifier:
                    return css = validators.validateIdentifier(value, props.moleculesList) ? css : "n4mp-table-cell-error"
                case Molecule.Smiles:
                    return css = SmilesValidator.validateSmiles(value.smiles) ? css : "n4mp-table-cell-error"
                case Molecule.MeltingPoint:
                    return css = validators.validateMelting(fieldToString(value.meltingPoint)) ? css : "n4mp-table-cell-error"
                case Molecule.EnthalpyOfFusion:
                    return css = validators.validateEnthalpy(fieldToString(value.enthalpyOfFusion)) ? css : "n4mp-table-cell-error"
                case Molecule.WaterSolubility:
                    return css = validators.validateWater(fieldToString(value.waterSolubility)) ? css : "n4mp-table-cell-error"
                case Molecule.PH:
                    return css = validators.validatePH(fieldToString(value.ph)) ? css : "n4mp-table-cell-error"
                case Molecule.LogP:
                    return css = validators.validateLogP(fieldToString(value.logP)) ? css : "n4mp-table-cell-error"
                default:
                    throw new Error(`Unsupported NUMBERfIELD id: ${numberField}`);
            }
    }, [props]);


    useEffect(() => {
       
    },[props.moleculesList]);

    const theme = createTheme({
        overrides: {
          MuiTooltip: {
            tooltip: {
              fontSize: "0.9em"
            }
          }
        }
      });


    return(
        <div className="molecules-list">
            <div className="title">
                <h1>List of molecules to submit </h1>
                <div className="separator-width" />
                <div className="total"><div>{props.moleculesList.length}</div></div>
            </div>
            
            <ContainerLayout>
                <div className="actions">
                    <ActionButton text={"Remove All"} color={"blue"} icon={<RemoveAllSVG />} isDisable={props.moleculesList.length === 0} onClick={() => props.onDeleteAll()}/>
                    <div className="separator-width" />
                    <ActionButton text={"Help"} icon={<HelpSVG />} color={"blue"} onClick={() => { setShowPopup(true); setPopupContent(<SubmissionTableHelpPopUp setShowPopup={setShowPopup}/>)}} />
                </div>

                <div className="container-tableList">
                    <Table
                        className="n4mp-table-nopagination"
                        dataTotalItems={props.moleculesList.length}
                        dataList={props.moleculesList}
                        columnDefinition={
                            [
                                {
                                    key: 'identifier',
                                    headerLabel: 'Identifier',
                                    isHidden: width < 900,
                                    sortable: true,
                                    valueTransform: (value: MoleculeEntry) => <> {value.name}</>,
                                    cellClassNames: (value: MoleculeEntry) => validateLineOfList(value,Molecule.Identifier)
                                        
                                },
                                {
                                    key: 'smiles',
                                    headerLabel: 'SMILES',
                                    isHidden: width < 900,
                                    sortable: true,
                                    valueTransform: (value: MoleculeEntry) => <>{value.smiles}</>,
                                    cellClassNames: (value: MoleculeEntry) => validateLineOfList(value,Molecule.Smiles)
                                },
                                {
                                    key: 'meltingPoint',
                                    headerLabel: 'MP',
                                    sortable: true,
                                    valueTransform: (value: MoleculeEntry) => <>{fieldToString(value.meltingPoint)}</>,
                                    cellClassNames: (value: MoleculeEntry) =>  validateLineOfList(value,Molecule.MeltingPoint)
                                },
                                {
                                    key: 'enthalpyOfFusion',
                                    headerLabel: 'EF',
                                    sortable: true,
                                    valueTransform: (value: MoleculeEntry) => <>{fieldToString(value.enthalpyOfFusion)}</>,
                                    cellClassNames: (value: MoleculeEntry) => validateLineOfList(value,Molecule.EnthalpyOfFusion)
                                },
                                {
                                    key: 'waterSolubility',
                                    headerLabel: 'WS',
                                    sortable: true,
                                    isHidden: width < 900,
                                    valueTransform: (value: MoleculeEntry) => <>{fieldToString(value.waterSolubility)}</>,
                                    cellClassNames: (value: MoleculeEntry) => validateLineOfList(value,Molecule.WaterSolubility)
                                }
                                ,
                                {
                                    key: 'ph',
                                    headerLabel: 'pH',
                                    sortable: true,
                                    isHidden: width < 900,
                                    valueTransform: (value: MoleculeEntry) => <>{fieldToString(value.ph)}</>,
                                    cellClassNames: (value: MoleculeEntry) => validateLineOfList(value,Molecule.PH)
                                }
                                ,
                                {
                                    key: 'logp',
                                    headerLabel: 'LogP',
                                    sortable: true,
                                    isHidden: width < 900,
                                    valueTransform: (value: MoleculeEntry) => <>{fieldToString(value.logP)}</>,
                                    cellClassNames: (value: MoleculeEntry) => validateLineOfList(value,Molecule.LogP)
                                },
                                {
                                    key: 'excelLine',
                                    headerLabel: 'Excel Line',
                                    isHidden: width < 900,
                                    sortable: false, 
                                    valueTransform: (value: MoleculeEntry) => (
                                        <>
                                        <MuiThemeProvider theme={theme}>
                                            <Tooltip
                                                title={fieldToString(value.excelFileName)}
                                                placement="top"   
                                            >
                                                <div >{fieldToString(value.excelLine)}</div>
                                            </Tooltip>
                                        </MuiThemeProvider>
                                        </>),
                                    cellClassNames: "n4mp-table-cell",
                                   
                                },
                                {
                                    key: 'edit',
                                    headerLabel: 'Edit',
                                    isHidden: width < 900,
                                    sortable: false,
                                    valueTransform: (value: MoleculeEntry) =>
                                        <div className="svg-content">
                                            <EditSVG className="svg" onClick={() => props.onEdit(value)}/>
                                        </div>
                                },
                                {
                                    key: 'remove',
                                    headerLabel: '',
                                    isHidden: width < 900,
                                    sortable: false,
                                    valueTransform: (value: MoleculeEntry) =>
                                        <div className="svg-content">
                                            <RemoveSVG className="svg" onClick={() => props.onDelete(value)}/>
                                        </div>
                                }                            ]
                        }
                    />
                </div>

                <div className="container-accordion-list">
                    <Accordion list={props.moleculesList} onDeleteAll={props.onDeleteAll} component={(molecule) => <AccordionRowList list={props.moleculesList} molecule={molecule} onEdit={props.onEdit} onDelete={props.onDelete}/>} />
                </div>

                <div className="spacerHeight"/>
                
                <div className="form-actions">
                    <div className="actions">
                        <IconButton isDisable={!formIsValid} className="btn-white" isLoading={props.isLoading} text="Submit" onClick={() => props.onSubmit()} />
                    </div>
                </div>


                
                                       
            </ContainerLayout>
        </div>
    );
}
