import { useState, useCallback, useEffect } from 'react';
import { MoleculeEntry } from '../../models/domain/MoleculeEntry';
import { AnalisysService } from '../../services/AnalisysService';
import { SubmitPopUp } from '../../components/PopUps/SubmitPopUp';
import { useGlobalPopup } from '../../Infrastructure/UI/Hooks/useGlobalPopup';
import { MoleculeSubmission } from './components/MoleculeSubmission';
import { BatchDescriptionPopUp } from '../../components/PopUps/BatchDescriptionPopUp';
import { FullScreenLoader } from '../../Infrastructure/UI/Components/FullScreenLoader/FullScreenLoader';
import './submit-molecules-page.css'
import { MoleculesList, fieldToString } from './components/MoleculesList/MoleculesList';
import { useGlobalLoading } from '../../Infrastructure/UI/Hooks/useGlobalLoading';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { SubmissionsService } from '../../services/SubmissionsService';
import { ConfirmPopUp } from '../../components/PopUps/ConfirmPopUp';
import { ValidationsFields } from './ValidationsFields/validationsFields';
import { UploadFilePopUp } from '../../components/PopUps/UploadFilePopUp';
import { SmilesValidator } from './components/SubmissionForm/SmilesValidator';


export interface UploadFileStatus{
    removedDuplicates: number;
    possibleDuplicates: number;
    errors: number;
}

export function SubmitMoleculesPage() {
    const {setShowPopup, setPopupContent} = useGlobalPopup();
    const {setShowFSL, setFSLContent} = useGlobalLoading();

    const[moleculesList, setMoleculesList] = useState<MoleculeEntry[]>([]);
    const[errorMessage, setErrorMessage] = useState<string>("");
    const[globalErrorMessage, setGlobalErrorMessage] = useState<string>("");

    const[isLoading, setIsLoading] = useState<boolean>(false);
    const[resBatchId, setResBatchId] = useState<string>("")
    const [maximumNumberOfSubmissions, setMaximumNumberOfSubmissions] = useState(20); // if the API call fails we set the default value as 20.
    
    const[moleculeToEdit, setMoleculeToEdit] = useState<MoleculeEntry>()


    const[isEditing, setIsEditing] = useState<boolean>(false)


    const loadMaximumNumberOfSubmissions = useCallback(() => {
        let service = new SubmissionsService();
        setIsLoading(true);
        service.getMaximumNumberOfSubmissions().then(res => {
            setMaximumNumberOfSubmissions(res.maximumNumberOfSubmissions)
            
        }).catch(_ =>{
            setMaximumNumberOfSubmissions(20); 
        });
        setIsLoading(false);
    },[setIsLoading, setMaximumNumberOfSubmissions])


    useEffect(() => { // CompDidMount
        loadMaximumNumberOfSubmissions();
    },[loadMaximumNumberOfSubmissions])


    const cleanErrorMessage = useCallback(() => {
        setErrorMessage("");
    },[setErrorMessage])


    const addMolecule = useCallback((molecule: MoleculeEntry) => {
        let aux = [...moleculesList];
        let moleculesWithSameName = aux.filter(r => r.name === molecule.name);
        if(moleculesWithSameName && moleculesWithSameName.length){
            setErrorMessage("Molecule with the same identifier already exists!");
            return false;
        }
        setErrorMessage("");
        aux.push(molecule)
        setMoleculesList(aux);
        setIsEditing(false)
        return true;
    },[moleculesList, setMoleculesList, setErrorMessage, setIsEditing]);


    const uploadMolecules = useCallback((molecules: MoleculeEntry[]) => {
        const validator = new ValidationsFields();
        let popUpMessage = "";
        let currentMolecules = [...moleculesList];

        const info : UploadFileStatus = {
            removedDuplicates: 0,
            possibleDuplicates: 0,
            errors: 0,
        };

        // Remove Strictly Equal Molecules
        molecules.forEach(molecule => {
            let strictlyEqualMolecules = currentMolecules.filter(r => validator.moleculeIsTheSame(r,molecule));
            if(strictlyEqualMolecules && strictlyEqualMolecules.length){
               info.removedDuplicates++;
               return;
            }
            currentMolecules.push(molecule);
        });

        //Detect Potentially equal molecules
        currentMolecules.forEach(molecule => {
            let potentiallyEqualMolecules = currentMolecules.filter(r => r !== molecule && validator.moleculeIsPotentiallyTheSame(r,molecule));
            if(potentiallyEqualMolecules && potentiallyEqualMolecules.length){
               info.possibleDuplicates++;
            }
        });

        //Detect Errors
        currentMolecules.forEach(molecule => {
            if(!validator.validateIdentifier(molecule,currentMolecules)){ info.errors++; }
            if(!SmilesValidator.validateSmiles(molecule.smiles)){ info.errors++; }
            if(!validator.validateMelting(fieldToString(molecule.meltingPoint))){ info.errors++; }
            if(!validator.validateEnthalpy(fieldToString(molecule.enthalpyOfFusion))){ info.errors++; }
            if(!validator.validateWater(fieldToString(molecule.waterSolubility))){ info.errors++; }
            if(!validator.validatePH(fieldToString(molecule.ph))){ info.errors++; }
            if(!validator.validateLogP(fieldToString(molecule.logP))){ info.errors++; }
        });

        setMoleculesList(currentMolecules)

        popUpMessage = popUpMessage !== "" ? popUpMessage : (currentMolecules.length + " molecule(s) uploaded from file!")
        //setShowFSL(false)
        setPopupContent(<UploadFilePopUp message={popUpMessage} status={info} setShowPopup={setShowPopup}/>);
        setShowPopup(true);
    },[moleculesList, setMoleculesList, setPopupContent, setShowPopup]);


    const deleteMolecule = useCallback((molecule: MoleculeEntry) => {
        console.log("delete " + molecule.name)
        let aux = [...moleculesList.filter(m => m !== molecule)];
        setMoleculesList(aux);
    },[moleculesList, setMoleculesList]);


    const succssesSubmitMolecule = useCallback((BatchId: any) => {
        try{
            setShowPopup(true)
            setPopupContent(<SubmitPopUp batchId={BatchId} onClose={() => setShowPopup(false)}/>);
        }catch(err){
            //TODO pop up fail
            setPopupContent(<SubmitPopUp batchId={BatchId} onClose={() => setShowPopup(false)}/>);
            //setShowPopup(true);
        }
    },[setShowPopup, setPopupContent]);


    const submitMolecule = useCallback(async (batchDescription: string) => {
        let service = new AnalisysService();
        try{
            setShowFSL(true)
            setFSLContent(<FullScreenLoader />)
            var res = await service.submitMolecules(batchDescription, moleculesList).then(res => {
                setResBatchId(res.batchId)
                setShowFSL(false)
                succssesSubmitMolecule(res.batchId)
            }).catch(res => {
                if(res?.response?.data?.errorMessage){
                    setGlobalErrorMessage(res.response.data.errorMessage);
                    setIsLoading(false);
                    setShowFSL(false);
                    moveToErrorMessage();
                }else{
                    
                    setGlobalErrorMessage("An error occurred while submiting. Please try again later.");

                    setIsLoading(false);
                    setShowFSL(false);
                    moveToErrorMessage();
                }     
            });
                   
        }catch(err){
            //TODO pop up fail
            setIsLoading(false);
            setGlobalErrorMessage("An error occurred while submiting. Please try again later.");
        }
    },[setShowFSL, setFSLContent, moleculesList, succssesSubmitMolecule]);
    

    function moveToErrorMessage(){
        var element = document.getElementsByClassName("molecule-submission")[0];
        element?.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});  
    }

    const addDescription = useCallback(() => {
        setGlobalErrorMessage("");

        if(moleculesList?.length > maximumNumberOfSubmissions){
            setGlobalErrorMessage(`Maximum number of molecules allowed by submission (${maximumNumberOfSubmissions}) exceeded!`);

            moveToErrorMessage();

            return;
        }

        setShowPopup(true);
        setPopupContent(<BatchDescriptionPopUp setShowPopup={setShowPopup} setBatchDescription={submitMolecule}/>);
    },[moleculesList?.length, maximumNumberOfSubmissions, setShowPopup, setPopupContent, submitMolecule]);


    const clearAllMoleculesFromTable = useCallback(() => {
        setMoleculesList([]);
        setShowPopup(false)
    },[setMoleculesList, setShowPopup]);

    const showDeleteAllMoleculesPopUp = useCallback(() => {
        setShowPopup(true)
        setPopupContent(<ConfirmPopUp title={"Delete All Molecules"} message={"Are you sure you want to delete all molecules?"} setShowPopup={() => setShowPopup(false)} onClick={clearAllMoleculesFromTable} />);
    },[setShowPopup, setPopupContent, clearAllMoleculesFromTable]);


    const editMoleculeFromTable = useCallback((molecule: MoleculeEntry) => {
        if(isEditing){
            setShowPopup(true);
            setPopupContent(
                <ConfirmPopUp title={"Edit Molecule"} message={"You are already editing a molecule. If you continue that molecule will be lost!"} 
                    setShowPopup={() => setShowPopup(false)} onClick={() => {setIsEditing(true); deleteMolecule(molecule); setMoleculeToEdit(molecule); setShowPopup(false)}} />
            );
        }else{
            setIsEditing(true)
            deleteMolecule(molecule)
            setMoleculeToEdit(molecule)
        }
    },[deleteMolecule, setMoleculeToEdit, setIsEditing, isEditing, setShowPopup, setPopupContent]);


    return (
        <div className="page submit-page">
            <div className="page-content submit-page-content">
                {globalErrorMessage?.trim()!==""? 
                    <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert severity="error" >{globalErrorMessage}</Alert>
                    </Stack> : null
                }
                <MoleculeSubmission 
                    addMolecule={addMolecule} 
                    uploadMolecules={uploadMolecules} 
                    errorMessage={errorMessage} 
                    cleanErrorMessage={cleanErrorMessage}
                    moleculeToEdit={moleculeToEdit}
                    // deleteMolecule={deleteMolecule}
                    // setMoleculeToEdit={setMoleculeToEdit}
                />

                <MoleculesList 
                    isLoading={isLoading}
                    moleculesList={moleculesList} 
                    onSubmit={() => {addDescription()}}
                    onDelete={deleteMolecule}
                    onDeleteAll={showDeleteAllMoleculesPopUp}
                    onEdit={editMoleculeFromTable}

                />
            </div>
        </div>
    );
}