import { ReactComponent as MenuDown} from '../../assets/svg/menu-down.svg'
import { ReactComponent as MenuUp} from '../../assets/svg/menu-up.svg'
import './Accordion.css'
import { IMoleculeResult } from '../../models/result-matrix-element';
import { useState } from 'react';

interface IProps{
    molecule: IMoleculeResult;
}

export function AccordionRowResults(props: IProps){
    
    const[isOpen, setIsOpen] = useState(false);

    return(
        <div className="accordion-row">
            <div className="accordion-row-close">
                <div className="accordion-title">{props.molecule.name}</div>
                    {isOpen ?
                        <MenuUp className="accordion-svg" onClick={() => setIsOpen(false)}/>
                    :
                        <MenuDown className="accordion-svg" onClick={() => setIsOpen(true)}/>
                    }
                </div>
            
                { isOpen ?
                    <>
                        <div className="accordion-separator-height" />
                        <div className="accordion-row-open">
                            <div className="accordion-info">
                                <div>BatchID</div>
                                <div>{props.molecule.batch_id}</div>
                            </div>
                            <div className="accordion-info">
                                <div>PC</div>
                                <div>{props.molecule.propensity_to_crystallize}</div>
                            </div>
                            <div className="accordion-info">
                                <div>Solubility</div>
                                <div>{props.molecule.solubility}</div>
                            </div>
                            <div className="accordion-info">
                                <div>Date</div>
                                <div>"Date"</div>
                            </div>
                        </div>
                    </>
                : null
                }
        </div>
    );
}