import { useCallback, useEffect, useMemo, useState } from 'react';
import './submissions-page.css';
import { SubmissionsService } from '../../services/SubmissionsService';
import { AnalysisSubmissionSummary } from '../../models/domain/AnalisysBatchSummary';
import { ContainerLayout } from '../../components/Container/ContainerLayout';
import { Table } from '../../components/Table/Table';
import { Checkbox } from '../../components/Checkbox/Checkbox';
import { Accordion } from '../../components/Accordion/Accordion';
import { AccordionRowDashboard } from '../../components/Accordion/AccordionRowDashboard';
import { IconButton } from '../../components/IconButton/IconButton';
import { useViewport } from '../../Hooks/UseViewPort';
import { useHistory } from 'react-router-dom';
import { AnalysisSubmissionSummaryRequestDTO } from '../../models/api/analysis-submission-summary-request-dto';


export function SubmissionsPage() {
    const [submissionsList, setSubmissionsList] = useState<AnalysisSubmissionSummary[]>([]);
    const { width } = useViewport();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const history = useHistory();

    const [selectedSubmissionsIds, setSelectedSubmissionsIds] = useState<string[]>([]);

    const [pageLength, setPageLength] = useState(10);
    const [actualPage, setActualPage] = useState(0);
    const [totalRows, setTotalRows] = useState(0);


    const addSelectedSubmission = useCallback( (id: string) => {
        let selectedSubmissionsCopy = [...selectedSubmissionsIds]
        selectedSubmissionsCopy.push(id)
        setSelectedSubmissionsIds(selectedSubmissionsCopy)
    },[selectedSubmissionsIds, setSelectedSubmissionsIds])


    const removeSelectedSubmission = useCallback((id: string) => {
        let selectedSubmissionsCopy = [...selectedSubmissionsIds.filter(a => a !== id)]
        setSelectedSubmissionsIds(selectedSubmissionsCopy)
    },[selectedSubmissionsIds, setSelectedSubmissionsIds])


    const loadSubmissions = useCallback(() => {
        setSubmissionsList([]);
        setTotalRows(0)
        let service = new SubmissionsService();
        setIsLoading(true);
        let request: AnalysisSubmissionSummaryRequestDTO ={
            pageLength: pageLength,
            currentPage: actualPage
        }
        service.getSubmissionsSummary(request).then(res => {
            setSubmissionsList(res.records);
            setTotalRows(res.total)
            setIsLoading(false);
        })
    },[setIsLoading, setSubmissionsList, setTotalRows, pageLength, actualPage])


    useEffect(() => {
        loadSubmissions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[actualPage, pageLength])


    const handleResultsClicked = useCallback(() => {
        let query = selectedSubmissionsIds.map(id => "id="+id).join("&");
        history.push({pathname: '/app/results', search: "?"+query})
    }, [history, selectedSubmissionsIds]);


    const cssClasses = useMemo(() => {
        if(totalRows < 1){
            return "iconbutton btn-white disable";
        } else {
            return "iconbutton btn-white"
        }
    }, [totalRows])


    return (
        <div className="page submissions-page">
            <div className="page-content submissions-page-content">
                <h1>Submissions Dashboard</h1>
                <ContainerLayout>
                    <div className="container-tableDashboard">
                        <Table
                            isLoading={isLoading}
                            className="n4mp-table-nopagination"
                            currentPage={actualPage}
                            onCurrentPageChanged={setActualPage}
                            itemsPage={pageLength}
                            onRowsPerPageChange={setPageLength}
                            dataTotalItems={totalRows}
                            dataList={submissionsList}
                            columnDefinition={
                                [
                                    {
                                        key: 'reference',
                                        headerLabel: '',
                                        isHidden: width < 900,
                                        sortable: false,
                                        valueTransform: (value: AnalysisSubmissionSummary) =>
                                            <div className="n4mp-table-cell-align_center">
                                                {value.status?.trim()?.toLowerCase() === "completed"?  // only allow completed submissions to be selected
                                                    <Checkbox isChecked={selectedSubmissionsIds.filter(v => v === value.id).length > 0}
                                                        onToogle={(isChecked)=> {
                                                            if(isChecked){
                                                                addSelectedSubmission(value.id);
                                                            } else {
                                                                removeSelectedSubmission(value.id);
                                                            }
                                                    }} />
                                                : null}
                                            </div> ,
                                    },
                                    {
                                        key: 'batchid',
                                        headerLabel: 'Batch Description',
                                        isHidden: width < 900,
                                        sortable: true,
                                        valueTransform: (value: AnalysisSubmissionSummary) => <>{value.batchDescription ? value.batchDescription : value.batchId}</>
                                    },
                                    {
                                        key: 'submissionDate',
                                        headerLabel: 'Submission Date (UTC)',
                                        sortable: true,
                                        valueTransform: (value: AnalysisSubmissionSummary) => <>{value.submissionTimeStamp}</>
                                    },
                                    {
                                        key: 'status',
                                        headerLabel: 'Status',
                                        sortable: true,
                                        valueTransform: (value: AnalysisSubmissionSummary) => <>{value.status}</>
                                    },
                                    {
                                        key: 'statusDate',
                                        headerLabel: 'Last Status Update Time (UTC)',
                                        sortable: true,
                                        isHidden: width < 900,
                                        valueTransform: (value: AnalysisSubmissionSummary) => <>{value.statusLastUpdated}</>
                                    }
                                ]
                            }
                            paginationOptions={[
                                {key:10, label:10},
                                {key:20, label:20},
                                {key:50, label:50}
                            ]}
                        />
                    </div>

                    <div className="container-accordion-dashboard">
                        <Accordion
                            list={submissionsList}
                            isLoading={isLoading}
                            currentPage={actualPage}
                            dataTotalItems={totalRows}
                            onCurrentPageChanged={setActualPage}
                            itemsPage={pageLength}
                            onRowsPerPageChange={setPageLength}
                            component={(submission) => <AccordionRowDashboard selectedSubmissionsIds={selectedSubmissionsIds} addSelectedSubmission={addSelectedSubmission} removeSelectedSubmission={removeSelectedSubmission} submission={submission} />}
                            paginationOptions={[
                                { key: 10, label: 10 },
                                { key: 20, label: 20 },
                                { key: 50, label: 50 }
                            ]}/>
                    </div>

                    <div className="spacerHeight"/>

                    <div className="form-actions">
                        <div className="actions">
                            <IconButton className={cssClasses} text="Results Report" onClick={() => isLoading ? {} : handleResultsClicked()} />
                        </div>
                    </div>
                </ContainerLayout>
            </div>
        </div>
    )
}
