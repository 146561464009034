import { ConditionalRender } from '../Directives/ConditionalRender';
import './Accordion.css'
import { AccordionEmpty } from './AccordionEmpty';
import { TableFooter } from '../Table/TableFooter/TableFooter';
import React from 'react';


export interface ITableColumn<T> {
    key: string;
    headerLabel: string | any;
    sortable: boolean;
    valueTransform?: (value: T) => React.ReactNode;
    cellClassNames?: string[];
    isHidden?: boolean;

}

export interface IKeyLabel{
    key: number
    label: number
}

interface IProps<T>{
    list: T[];
    component: (row: T) => JSX.Element;
    isLoading?: boolean;
    paginationOptions?: IKeyLabel[];
    currentPage?: number;
    itemsPage?: number;
    dataTotalItems?: number;
    columnDefinition?: ITableColumn<T>[];
    onCurrentPageChanged?: (newPage: number) => void;
    onRowsPerPageChange?: (rowsPerPage: number) => void;
    onDeleteAll?: () => void;
}

export function Accordion<T>(props: IProps<T>){
    return(
        <div className="results-accordion">
            {props.list?.length !== 0
            ?
                <>
                    {props.list?.map((row, idx) =>
                        <React.Fragment key={idx}>
                            {props.component(row)}
                            {idx !== (props.list.length - 1)
                                ? <div className="accordion-separator-height" />
                                : null
                            }
                        </React.Fragment>
                    )}
                </>
            :
                props?.isLoading
                    ? <AccordionEmpty isLoading={props.isLoading}/>
                    : <AccordionEmpty isLoading={props.isLoading} message={"No Data"}/>
            }
            <ConditionalRender if={props.paginationOptions && !!props.paginationOptions.length}>
                <TableFooter
                    totalItems={props.dataTotalItems || 0}
                    paginationOptions={props.paginationOptions || []}
                    currentPage={props.currentPage || 0}
                    currentPageItemCount={props.list?.length}
                    itemsPage={props.itemsPage}
                    onCurrentPageChanged={props.onCurrentPageChanged}
                    onRowsPerPageChanged={props.onRowsPerPageChange}
                />
            </ConditionalRender>
        </div>
    );
}
