import { AuthenticationResult, EndSessionRequest, EventType, InteractionStatus, IPublicClientApplication, PublicClientApplication, RedirectRequest } from "@azure/msal-browser";
import { Configuration } from "../../configs/configuration";
import { IdentityHelper, MarketplaceClaims } from "./Identity.Helper";
import { IdentityProvider } from './IdentityProvider';
import { UrlUtility } from '../../utils/UrlUtility';
import { ConfigurationProvider } from '../Configuration/ConfigurationProvider';


export class IdentityService {

    static urlsRelativePaths ={
        DIRECT_IDENTITY_LOGIN: "Identity/SignIn",
        DIRECT_IDENTITY_LOGOUT: "Identity/SignOut",
        DIRECT_IDENTITY_SIGNUP: "Identity/SignUp",
        DIRECT_IDENTITY_EDITPROFILE : "Identity/EditProfile",
        DIRECT_IDENTITY_RESETPASSWORD : "Identity/ForgottenPassword",
    }


    static loginUrl() {
        return Configuration.baseUrl() + IdentityService.loginRelativeUrl();
    }

    static logoutUrl() {
        return Configuration.baseUrl() + IdentityService.logoutRelativeUrl();
    }

    static signupUrl() {
        return Configuration.baseUrl() + IdentityService.signupRelativeUrl();
    }

    static editprofileUrl() {
        return Configuration.baseUrl() +  IdentityService.editprofileRelativeUrl();
    }

    static resetpasswordUrl() {
        return Configuration.baseUrl() + IdentityService.resetpasswordRelativeUrl();
    }

    static redirectUri() {
        return Configuration.baseUrl();
    }



    static signupRelativeUrl() {
        return IdentityService.urlsRelativePaths.DIRECT_IDENTITY_SIGNUP;
    }

    static loginRelativeUrl() {
        return IdentityService.urlsRelativePaths.DIRECT_IDENTITY_LOGIN;
    }

    static logoutRelativeUrl() {
        return IdentityService.urlsRelativePaths.DIRECT_IDENTITY_LOGOUT;
    }

    static editprofileRelativeUrl() {
        return IdentityService.urlsRelativePaths.DIRECT_IDENTITY_EDITPROFILE;
    }

    static resetpasswordRelativeUrl() {
        return IdentityService.urlsRelativePaths.DIRECT_IDENTITY_RESETPASSWORD;
    }

    static Global: IdentityService;
    protected static isAuthenticated: boolean = false;
    protected static inProgress: InteractionStatus;


    static initInstance(msalInstance: PublicClientApplication){

        if(!msalInstance){
            throw new Error("Invalid MSAL Client");
        }

        IdentityService.Global = new IdentityService(msalInstance);

        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
            msalInstance.setActiveAccount(accounts[0]);
        }

        msalInstance.addEventCallback((event) => {

            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                const payload = event.payload as AuthenticationResult;
                const account = payload.account;
                msalInstance.setActiveAccount(account);
            }

            //console.log(message);
            if(event.error){

                console.log("MSAL Error", event);
            }
            //AADB2C90118: The user has forgotten their password.
            if(IdentityHelper.errorMessageContains(event, 'AADB2C90118')){
                window.location.href = IdentityService.resetpasswordUrl();
            }

            //AADB2C90091: The user has cancelled entering self-asserted information.
            if(IdentityHelper.errorMessageContains(event, 'AADB2C90091')){
                window.location.href = '/';
            }
        });
    }


    static userIsAuthenticated() : boolean { return IdentityService.isAuthenticated; }

    static setAuthenticated(isAuthenticated: boolean){
        IdentityService.isAuthenticated = isAuthenticated;
        if(!isAuthenticated){
            IdentityService.Global.saveClaims(null);
        }
    }

    static setInProgress(progress: InteractionStatus){
        IdentityService.inProgress = progress;
    }

    static getInProgress(){
        return IdentityService.inProgress;
    }



    /** -------------------------------------------------------------
     *
     *  Identity Service With MSAL
     *
     *  ------------------------------------------------------------- */

        msalInstance: IPublicClientApplication;

        constructor(instance: IPublicClientApplication){
            this.msalInstance = instance;
        }

        login() {
            let request: RedirectRequest = {
                ...IdentityProvider.getBaseLoginRequest(),
                redirectStartPage: UrlUtility.join(ConfigurationProvider.getConfiguration().App.BaseUrl,"app"),
                redirectUri: IdentityService.redirectUri(),
            };
            return this.msalInstance.loginRedirect(request);
        }

        logout  () {
            this.saveClaims(null);
            let request: EndSessionRequest = {
                postLogoutRedirectUri: IdentityService.redirectUri(),
            }
            return this.msalInstance.logoutRedirect(request);
        }

        signUp() {
            let request: RedirectRequest = {
                ...IdentityProvider.getBaseLoginRequest(),
                authority: IdentityProvider.getB2CPolicies().authorities.signUp.authority,
                redirectStartPage: ConfigurationProvider.getConfiguration().App.BaseUrl,
                redirectUri: IdentityService.redirectUri(),
            }
            return this.msalInstance.loginRedirect(request);
        }

        forgottenPassword() {
            let request: RedirectRequest = {
                ...IdentityProvider.getBaseLoginRequest(),
                redirectUri: IdentityService.redirectUri(),
                redirectStartPage:  ConfigurationProvider.getConfiguration().App.BaseUrl,
                authority: IdentityProvider.getB2CPolicies().authorities.forgotPassword.authority,
            }
            return this.msalInstance.loginRedirect(request);
        }

        loginBackToUrl(url: string){
            let request: RedirectRequest = {
                ...IdentityProvider.getBaseLoginRequest(),
                redirectUri: IdentityService.redirectUri(),
                redirectStartPage: url,
            }
            return this.msalInstance.loginRedirect(request);
        }


        editProfileBackToUrl(url: string){
            let request: RedirectRequest = {
                ...IdentityProvider.getBaseLoginRequest(),
                redirectUri: IdentityService.redirectUri(),
                authority: IdentityProvider.getB2CPolicies().authorities.editProfile.authority,
                redirectStartPage: url,
            }
            return this.msalInstance.loginRedirect(request);
        }


        async getAuthenticationResult(): Promise<AuthenticationResult | void | null> {


            const activeAccount = this.msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
            const accounts = this.msalInstance.getAllAccounts();


            if(IdentityService.inProgress !== InteractionStatus.None ) {
                var res = await this.msalInstance.handleRedirectPromise();
                if(res){
                    console.log("Handle Promise", res);
                    return res;
                }

            }

            const request = {
             ...IdentityProvider.getBaseLoginRequest(),
              account: activeAccount || accounts[0]
            };

            const redirectRequest: RedirectRequest = {
              ...IdentityProvider.getBaseLoginRequest(),
              account: activeAccount || accounts[0]
            }


            // Silently acquires an access token which is then attached to a request for Microsoft Graph data
            return this.msalInstance
            .acquireTokenSilent(request)
            .then(authResult => {
                this.saveClaims(authResult.idTokenClaims as MarketplaceClaims);
                return authResult;
            })
            .catch((e) => {
              console.log("Aquire Redirect", e); //TODO: redirect back to the same page???
                return this.msalInstance
                .acquireTokenRedirect(redirectRequest)
                .catch((e) => {
                    return null;
                  });;

            });

        }

        //Hearbeat purposes
        tryAquireAccessToken(): Promise<string | null>{
            const activeAccount = this.msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
            const accounts = this.msalInstance.getAllAccounts();

            if(IdentityService.inProgress !== InteractionStatus.None ) {
                return this.msalInstance.handleRedirectPromise().then(authResult => {

                    if (authResult && authResult as AuthenticationResult){
                        this.saveClaims(authResult.idTokenClaims as MarketplaceClaims);
                        return authResult.accessToken;
                    }
                    return null;
                })
                .catch((e) => {
                  return null;
                });;
            }


            const request = {
             ...IdentityProvider.getBaseLoginRequest(),
              account: activeAccount || accounts[0]
            };

            // const redirectRequest: RedirectRequest = {
            //   ...IdentityProvider.getBaseLoginRequest(),
            //   account: activeAccount || accounts[0]
            // }


            // Silently acquires an access token which is then attached to a request for Microsoft Graph data
            return this.msalInstance
            .acquireTokenSilent(request)
            .then(authResult => {
                this.saveClaims(authResult.idTokenClaims as MarketplaceClaims);
                if (authResult && authResult as AuthenticationResult){
                    return authResult.accessToken;
                }
                return null;
            })
            .catch((e) => {
              return null;
            });
        }


        getAccessToken(): Promise<string | void> {
            return this.getAuthenticationResult()
            .then(result => {

              if (result && result as AuthenticationResult){
                return result.accessToken;
              }
            })
        }



        static storageKey = "NANOFORM_STARMAP_STORAGE_IDENTITY_CLAIMS";

        saveClaims(claims: MarketplaceClaims | null){
            if (claims) {
                window.localStorage.setItem(IdentityService.storageKey, JSON.stringify(claims));
              } else {
                window.localStorage.removeItem(IdentityService.storageKey);
              }
        }

        getClaims(): MarketplaceClaims{
            let claims = window.localStorage.getItem(IdentityService.storageKey);
            return claims && JSON.parse(claims);
        }
}
