import './MoleculeSubmission.css'
import { MoleculeEntry } from '../../../models/domain/MoleculeEntry';
import { TemplateCard } from './TemplateCard/TemplateCard';
import { SubmissionForm } from './SubmissionForm/SubmissionForm';


interface IProps{
    errorMessage: string;
    addMolecule: (molecule: MoleculeEntry) => boolean;
    uploadMolecules: (molecules: MoleculeEntry[]) => void;
    cleanErrorMessage: () => void;
    moleculeToEdit: MoleculeEntry | undefined;
    // deleteMolecule: (molecule: MoleculeEntry) => void;
    // setMoleculeToEdit: (molecule: MoleculeEntry) => void;
}


export function MoleculeSubmission(props: IProps){
    return(
        <div className="molecule-submission">
            <h1>Molecule submission form</h1>
            <div className="molecule-submission-content">
                <TemplateCard uploadMolecules={props.uploadMolecules}/>
                <SubmissionForm 
                    addMolecule={props.addMolecule} 
                    errorMessage={props.errorMessage} 
                    cleanErrorMessage={props.cleanErrorMessage}
                    moleculeToEdit={props.moleculeToEdit}
                    // deleteMolecule={props.deleteMolecule}
                    // setMoleculeToEdit={props.setMoleculeToEdit}
                />
            </div>
        </div>
    );
}
