import { MoleculeEntry } from '../../../models/domain/MoleculeEntry';


export class ValidationsFields {

    onRegexValue(value: any): string{
        let regex = /\d+(\.\d*)?/g;
        let arr = regex.exec(value) || [""];
        let val = "" + value;
        if(val.charAt(0) === "-"){
            return "-" + arr[0];
        } else {
            return arr[0];
        }
    }
    
    moleculeIsTheSame(moleculeA: MoleculeEntry, moleculeB :MoleculeEntry){
        if(moleculeA.name === moleculeB.name && moleculeA.smiles === moleculeB.smiles && moleculeA.meltingPoint === moleculeB.meltingPoint &&  moleculeA.enthalpyOfFusion === moleculeB.enthalpyOfFusion
            && moleculeA.waterSolubility === moleculeB.waterSolubility && moleculeA.ph === moleculeB.ph && moleculeA.logP === moleculeB.logP){
          return true;
        }
        return false;
    }

    moleculeIsPotentiallyTheSame(moleculeA: MoleculeEntry, moleculeB :MoleculeEntry){
        if(moleculeA.smiles === moleculeB.smiles 
            && moleculeA.meltingPoint === moleculeB.meltingPoint 
            &&  moleculeA.enthalpyOfFusion === moleculeB.enthalpyOfFusion
            && moleculeA.waterSolubility === moleculeB.waterSolubility 
            && moleculeA.ph === moleculeB.ph 
            && moleculeA.logP === moleculeB.logP){
          return true;
        }
        return false;
    }

    validateIdentifier(molecule: MoleculeEntry, moleculesList: MoleculeEntry[]): boolean{
        let aux = [...moleculesList];
        let moleculesWithSameName = aux.filter(r => r.name === molecule.name && r !== molecule);
        if(moleculesWithSameName && moleculesWithSameName.length > 0){
            return false;
        }
        return true;
    }

    validateMelting(melting: string): boolean{
        if(Number.parseFloat(this.onRegexValue(melting)) < 25){
            return false;
        }else{
            return true
        }
    }

    validateEnthalpy(enthalpy: string): boolean{
        if(Number.parseFloat(this.onRegexValue(enthalpy)) <= 0){
            console.log("false")
            return false;
        }else{
            return true
        }
    } 
    
    validateWater(water: string): boolean{
        if(Number.parseFloat(this.onRegexValue(water)) <= 0){
            return false;
        }else{
            return true
        }
    }

    validatePH(ph: string): boolean{
        if(Number.parseFloat(this.onRegexValue(ph)) < 0 || Number.parseFloat(this.onRegexValue(ph)) > 14){
            return false;
        }else{
            return true
        }
    }

    validateLogP(logP: string): boolean{
        if(Number.parseFloat(this.onRegexValue(logP)) < -50 || Number.parseFloat(this.onRegexValue(logP)) > 50){
            return false;
        }else{
            return true
        }
    }
        

}