import './TemplateCard.css'
import { ReactComponent as FileSVG } from '../../../../assets/svg/file-document.svg';
import { ContainerLayout } from '../../../../components/Container/ContainerLayout';
import { IconButton } from '../../../../components/IconButton/IconButton';
import { InputFile } from '../../../../components/input-file/input-file';
import { MoleculeEntry } from '../../../../models/domain/MoleculeEntry';
import FilesDownloadService from '../../../../services/FilesDownloadService';

interface IProps{
    uploadMolecules: (molecules: MoleculeEntry[]) => void;
}

let filesService = new FilesDownloadService();

export function TemplateCard(props:IProps){

    return(
        <ContainerLayout>
            <div className="template-card">
                <h2>Use our template!</h2>
                <p>Molecules to be analyzed can also be uploaded with a .xlsx file.</p>
                <div className="template-card-buttons">
                    <IconButton className="iconbutton btn-white" icon={<FileSVG/>} text="Download Template" href={filesService.downloadFile("MOLECULES_TEMPLATE")}/>
                    <InputFile name="Upload From File" loadData={(data: any[]) => props.uploadMolecules(data)}/>
                </div>
            </div>
        </ContainerLayout>
    );
}
