
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { IdentityService } from '../../Infrastructure/Identity/IdentityService';



export class HttpClient {


    static sessionRequest<T = any> (config: AxiosRequestConfig): Promise<AxiosResponse<T>> {


        return IdentityService.Global.getAccessToken().then(accessToken => {
            console.log("ACCESS TOKEN", accessToken);
            var requestOptions = {...config};

            if(!requestOptions.headers){
                requestOptions.headers = {}
            };

            if(accessToken) {
                requestOptions.headers[ "Authorization"] = `Bearer ${accessToken}`;
            }

            return axios.request<T>({...requestOptions})
            // .catch((error) => {

            //       if (error.response && error.response.status && error.response.status === 401) {
            //         window.location.href = IdentityService.loginUrl()  || "";
            //         window.location.hash = "";
            //       } else {
            //         throw error;
            //       }
            //     });

        });
    }

}
