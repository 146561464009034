import { useHistory } from "react-router-dom";
import { useCallback, useState } from "react";
import { ReactComponent as UserSvg } from '../../assets/svg/user.svg';
import { ReactComponent as LockSvg } from '../../assets/svg/lock.svg';
import { ReactComponent as BurgerSvg } from '../../assets/svg/burger.svg';
import { ReactComponent as XSvg } from '../../assets/svg/x.svg';
import "./Header.css"
import { DropdownMenu } from './components/DropdownMenu/DropdownMenu';
import logo from '../../assets/png/starmap-v2-1024x227.png';
import { HeaderButton } from './components/Button/HeaderButton';

interface IProps{
    title?:string;
    toGoSide?: () => void;
    isOpen?: boolean;
    isAdmin?: boolean;
}

export function Header(props:IProps){
    let history = useHistory();

    const [isOpen, setIsOpen] = useState<boolean>(false)

    // const handleClickSettings = useCallback((option: string) => {
    //     console.log(option)
    // },[]);

    const handleItemClick = useCallback((option: string) => {
        switch(option){
            case "Submit Analysis":
                history.push("");
                break;
            case "My Submissions":
                history.push("/sidebar");
                break;
            default:
                break;
        }
    },[history]);

    return(
        <div className="header" >
            <div className="header-logo">
                <img className="logo" src={logo} alt="starmap" onClick={() =>{history.push("/app/submissions")}}/>
            </div>

            <div className="header-actions">
                {props.isAdmin?
                    <>
                        <div className="admin-option">
                            <div className="lockSVG">
                            <LockSvg />
                            </div>
                            <HeaderButton text="Admin Dashboard" className="header-button" onClick={() => { history.push("/app/admin"); } } />
                        </div>
                    </>
                :null}
                <HeaderButton text="Submit Analysis" className="header-button" onClick={() => {history.push("/app/molecules")}}/>
                <HeaderButton text="My Submissions" className="header-button" onClick={() => {history.push("/app/submissions")}}/>
            </div>

            <div className="header-user n4mp-circle-avatar" onClick={() => setIsOpen(true)}>
                <div className="userSVG">
                    <UserSvg />
                </div>
            </div>

            { !props.isOpen
            ?
                <div className="header-burger" onClick={() => props.toGoSide && props.toGoSide()}>
                    <BurgerSvg/>
                </div>
            :
                <div className="header-burger z-index" onClick={() => props.toGoSide && props.toGoSide()}>
                    <XSvg/>
                </div>
            }

            {isOpen ?
                <div className="dropDownMenu">
                    <DropdownMenu
                        handleClickSettings={handleItemClick}
                        handleClickLogout={() => {history.push("/Identity/SignOut")}}
                        setIsOpen={setIsOpen}
                    />
                </div>
            : null}
        </div>
    )
}

/****************** OK *******************/
