import { MoleculeEntry } from "../models/domain/MoleculeEntry";
import { AnalisysBatch } from "../models/domain/AnalisysBatch";
import { HttpClient } from '../Infrastructure/Http/HttpClient';
import { SubmitAnalysisResponseDTO } from '../models/api/submit-analysis-response-dto';
import { ConfigurationProvider } from '../Infrastructure/Configuration/ConfigurationProvider';
import { UrlUtility } from "../utils/UrlUtility";
import { MoleculeDTO, SubmitAnalisysRequestDTO } from "../models/api/submit-analysis-request-dto";

export class AnalisysService {

    submitMolecules(batchDescription: string, molecules: MoleculeEntry[]): Promise<AnalisysBatch> {
        let requestBody: SubmitAnalisysRequestDTO = {
            molecules: molecules.map((m): MoleculeDTO => ({
                name: m.name,
                smiles: m.smiles,
                enthalpyOfFusion: m.enthalpyOfFusion,
                meltingPoint: m.meltingPoint,
                ph: m.meltingPoint,
                waterSolubility: m.waterSolubility,
                logP: m.logP,
            })),
            batchDescription: batchDescription
        };
        console.log(batchDescription);

        return HttpClient.sessionRequest<SubmitAnalysisResponseDTO>({
            method: 'POST',
            url: UrlUtility.join(ConfigurationProvider.getConfiguration().App.BackendUrl, "api/Analyze"),
            data: requestBody
        }).then(res => {

            let response: AnalisysBatch = {
                batchId: res.data.batchId
            };

            return response;
        })
    }
}
