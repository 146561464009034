import React, { ReactChild, ReactChildren, ReactElement, ReactNode, ReactNodeArray, useEffect } from "react";
import { MsalProvider } from '@azure/msal-react';
import { IdentityNavigationClient } from '../Identity.NavigationClient';
import { IdentityProvider } from '../IdentityProvider';
import { useHistory } from 'react-router';

interface IIdentityAppProps {
    children: ReactNode | ReactNodeArray | ReactElement | ReactChild | ReactChildren;
}


export function IdentityApp(props:IIdentityAppProps){

    const history = useHistory();

    useEffect(() => {
        const navigationClient = new IdentityNavigationClient(history);
        IdentityProvider.getMsalClient().setNavigationClient(navigationClient);
      }, [history])


    return (
        <MsalProvider instance={IdentityProvider.getMsalClient()}>
            {props.children}
        </MsalProvider>
    );
}
