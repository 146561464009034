import './UsersList.css'
import { ReactComponent as EditSVG } from '../../../../assets/svg/pencil-outline.svg';
import { ReactComponent as SearchSVG } from '../../../../assets/svg/search-icon.svg';
import { ContainerLayout } from '../../../../components/Container/ContainerLayout';
import { Table } from '../../../../components/Table/Table';
import { Accordion } from '../../../../components/Accordion/Accordion';
import { useViewport } from '../../../../Hooks/UseViewPort';
import { UserDetailsEntry } from './../../../../models/domain/UserDetailsEntry';
import { AccordionRowUserDetails } from '../../../../components/Accordion/AccordionRowUserDetails';
import { useState, useCallback } from 'react';
import { NewInput } from '../../../../components/Input/NewInput';
import { ReactComponent as RemoveSVG } from '../../../../assets/svg/close-circle-outline.svg';

interface IProps{
    isLoading?: boolean;
    usersList: UserDetailsEntry[];
    actualPage: number;
    pageLength: number;
    totalRows: number;
    onCurrentPageChanged: (actualPage: number) => void;
    onRowsPerPageChange: (pageLength: number) => void;
    onEdit: (user: UserDetailsEntry) => void;
    onDelete: (email: string) => void;
    onSearch: (email: string) => void;
}

export function UsersList(props: IProps){
    const { width } = useViewport();
    const [email, setEmail] = useState<string>("");

    const clearSearch = useCallback(()=>{
        setEmail("");
        props.onSearch("");
    },[setEmail, props]);

    return(
        <div className="users-list">
            <h1>Users Details</h1>
            <ContainerLayout>
                <div className='container-search'>
                        <NewInput type="text"
                            className="identifier-input"
                            value={email}
                            placeholder="Search by email"
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyPress={(e) => {if(e.key === 'Enter') props.onSearch(email)}}
                            onClear={clearSearch}/>
                        <SearchSVG className="search-svg" onClick={() => props.onSearch(email)}/>
                </div>
            </ContainerLayout>
            <ContainerLayout>
                <div className="container-tableList">
                    <Table
                        className="n4mp-table-nopagination"
                        isLoading={props.isLoading}
                        currentPage={props?.actualPage}
                        onCurrentPageChanged={props?.onCurrentPageChanged}
                        itemsPage={props?.pageLength}
                        onRowsPerPageChange={props?.onRowsPerPageChange}
                        dataTotalItems={props?.totalRows}
                        dataList={props?.usersList}
                        columnDefinition={
                            [
                                {
                                    key: 'email',
                                    headerLabel: 'Email',
                                    sortable: true,
                                    valueTransform: (value: UserDetailsEntry) => <>{value.email}</>
                                },
                                {
                                    key: 'isValidated',
                                    headerLabel: 'Valid User',
                                    sortable: true,
                                    valueTransform: (value: UserDetailsEntry) => <>{value.isValidated? "valid" : "invalid"}</>
                                },
                                {
                                    key: 'maximumSubmissions',
                                    headerLabel: 'Maximum Molecule Submissions',
                                    sortable: true,
                                    valueTransform: (value: UserDetailsEntry) => <>{value.maximumSubmissions}</>
                                },
                                {
                                    key: 'submissionsDone',
                                    headerLabel: 'Molecule Submissions Done',
                                    sortable: true,
                                    valueTransform: (value: UserDetailsEntry) => <>{value.submissionsDone}</>
                                },
                                {
                                    key: 'edit',
                                    headerLabel: '',
                                    isHidden: width < 900,
                                    sortable: false,
                                    valueTransform: (value: UserDetailsEntry) =>
                                        <div className="svg-content">
                                            <EditSVG className="svg" onClick={() => props.onEdit(value)}/>
                                        </div>
                                },
                                {
                                    key: 'delete',
                                    headerLabel: '',
                                    isHidden: width < 900,
                                    sortable: false,
                                    valueTransform: (value: UserDetailsEntry) =>
                                        <div className="svg-content">
                                            <RemoveSVG className="svg" onClick={() => props.onDelete(value.email)}/>
                                        </div>
                                }
                            ]
                        }
                        paginationOptions={[
                            {key:10, label:10},
                            {key:20, label:20},
                            {key:50, label:50}
                        ]}
                    />
                </div>

                <div className="container-accordion-list">
                    <Accordion
                            isLoading={props.isLoading}
                            list={props?.usersList}
                            currentPage={props?.actualPage}
                            onCurrentPageChanged={props?.onCurrentPageChanged}
                            itemsPage={props?.pageLength}
                            onRowsPerPageChange={props?.onRowsPerPageChange}
                            dataTotalItems={props?.totalRows}
                            component={(user) => 
                                <AccordionRowUserDetails user={user} 
                                    onEdit={props.onEdit}
                                    onDelete={props.onDelete}/>
                            }
                            paginationOptions={[
                                { key: 10, label: 10 },
                                { key: 20, label: 20 },
                                { key: 50, label: 50 }
                            ]}/>
                </div>
            </ContainerLayout>
        </div>
    );
}
