import { IconButton } from "../../components/IconButton/IconButton";
import "./PersonalizedErrorPage.css";
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

export default function PersonalizedErrorPage(){

  const history = useHistory();

  const [errorCode, setErrorCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const search = useLocation().search;

    useEffect(() => {

      let code = new URLSearchParams(search)?.get('errorCode') || "";
      let message = new URLSearchParams(search)?.get('message') || "";

      if(code === "503")
        message = "Service Unavailable! Please try again later.";

      else if(code === "403")
        message = "Access Denied! Your user does not have access to this page."

      else {
        code = "404";
        message ="Unfortunately, the page you're looking for could not be found."
      }

      setErrorCode(code);
      setErrorMessage(message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const message = errorCode || "404";
    return (


      <div className="errorPage-content">
            <div className="error-header">
              <h1 className="error-heading">{message}</h1>
            </div>
            <div className="error-subtitle"> {message === "404" ? "Page not found" : "Error"}</div>
            <div className="error-res">
              {" "}
              {errorMessage || "Unfortunately, the page you're looking for could not be found."}
            </div>
            <div className="error-height" />
            <div className="error-actions" id="test">
              <IconButton className="iconbutton btn-green" text="Home" onClick={() => history.push("/")}  />
            </div>
          </div>
    );

}
