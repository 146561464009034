import { ReactComponent as MenuDown} from '../../assets/svg/menu-down.svg'
import { ReactComponent as MenuUp} from '../../assets/svg/menu-up.svg'
import { ReactComponent as RemoveSVG} from '../../assets/svg/close-circle-outline.svg';
import { ReactComponent as EditSVG } from '../../assets/svg/pencil-outline.svg';
import './Accordion.css'
import { useCallback, useState } from 'react';
import { Molecule, MoleculeEntry } from '../../models/domain/MoleculeEntry';
import { ValidationsFields } from '../../areas/submit-molecules/ValidationsFields/validationsFields';
import { SmilesValidator } from '../../areas/submit-molecules/components/SubmissionForm/SmilesValidator';
import ReactTooltip from 'react-tooltip';
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

interface IProps{
    molecule: MoleculeEntry;
    list: MoleculeEntry[];
    onDelete: (molecule: MoleculeEntry) => void;
    onEdit: (molecule: MoleculeEntry) => void;
}

export function AccordionRowList(props: IProps){

    const[isOpen, setIsOpen] = useState(false);


    const fieldToString = useCallback((field: any) => {
        if(field === undefined || field === null)
            return "";

        return field;
    }, []);

    const validateLineOfList = useCallback((value: MoleculeEntry, numberField: number) => {
        const validators = new ValidationsFields()
        let css = "n4mp-table-cell";

        let numberOfPotentiallyTheSame = props.list.filter(x => validators.moleculeIsPotentiallyTheSame(x,value))
            if(numberOfPotentiallyTheSame.length && numberOfPotentiallyTheSame.length > 1){
                css = "n4mp-table-cell-potential-duplicated";
            }

        switch (numberField){
            case Molecule.Identifier:
                return css = validators.validateIdentifier(value, props.list) ? css : "n4mp-table-cell-error"
            case Molecule.Smiles:
                return css =  SmilesValidator.validateSmiles(value.smiles) ? css : "n4mp-table-cell-error";
            case Molecule.MeltingPoint:
                return css = validators.validateMelting(fieldToString(value.meltingPoint)) ? css : "n4mp-table-cell-error"
            case Molecule.EnthalpyOfFusion:
                return css = validators.validateEnthalpy(fieldToString(value.enthalpyOfFusion)) ? css : "n4mp-table-cell-error"
            case Molecule.WaterSolubility:
                return css = validators.validateWater(fieldToString(value.waterSolubility)) ? css : "n4mp-table-cell-error"
            case Molecule.PH:
                return css = validators.validatePH(fieldToString(value.ph)) ? css : "n4mp-table-cell-error"
            case Molecule.LogP:
                return css = validators.validateLogP(fieldToString(value.logP)) ? css : "n4mp-table-cell-error"
            default:
                throw new Error(`Unsupported NUMBERfIELD id: ${numberField}`);
        }
    }, [props, fieldToString]);

    const theme = createTheme({
        overrides: {
          MuiTooltip: {
            tooltip: {
              fontSize: "0.9em"
            }
          }
        }
    });

    return(
        <div className="accordion-row">
            <div className="accordion-row-close">

                <div className={validateLineOfList(props.molecule, Molecule.Identifier)}>{props.molecule.name}</div>
                    <div className="accordion-actions">
                        {isOpen ?
                            <div className="svg-content"><MenuUp className="svg" onClick={() => setIsOpen(false)}/></div>
                        :
                            <div className="svg-content"><MenuDown className="svg" onClick={() => setIsOpen(true)}/></div>
                        }
                        <div className="accordion-svg-spacer" />
                        <div className="svg-content"><RemoveSVG className="svg" onClick={() => props.onDelete(props.molecule)}/></div>
                        <div className="accordion-svg-spacer" />
                        <div className="svg-content"><EditSVG className="svg" onClick={() => props.onEdit(props.molecule)}/></div>
                    </div>
                </div>

                { isOpen ?
                    <>
                        <div className="accordion-separator-height" />
                        <div className="accordion-row-open">
                            <div className="accordion-info">
                                <div className='accordion-info-text'>SMILES</div>
                                <div className={validateLineOfList(props.molecule,Molecule.Smiles)}>{props.molecule.smiles}</div>
                            </div>
                            <div className="accordion-info">
                                <div className='accordion-info-text'>MP</div>
                                <div className={validateLineOfList(props.molecule,Molecule.MeltingPoint)}>{props.molecule.meltingPoint}</div>
                            </div>
                            <div className="accordion-info">
                                <div className='accordion-info-text'>EF</div>
                                <div className={validateLineOfList(props.molecule,Molecule.EnthalpyOfFusion)}>{props.molecule.enthalpyOfFusion}</div>
                            </div>
                            <div className="accordion-info">
                                <div className='accordion-info-text'>WS</div>
                                <div className={validateLineOfList(props.molecule,Molecule.WaterSolubility)}>{props.molecule.waterSolubility}</div>
                            </div>
                            <div className="accordion-info">
                                <div className='accordion-info-text'>Ph</div>
                                <div className={validateLineOfList(props.molecule,Molecule.PH)}>{props.molecule.ph}</div>
                            </div>
                            <div className="accordion-info">
                                <div className='accordion-info-text'>Log</div>
                                <div className={validateLineOfList(props.molecule,Molecule.LogP)}>{props.molecule.logP}</div>
                            </div>
                            <div className="accordion-info">
                                <div className='accordion-info-text'>Excel Line</div>
                                <MuiThemeProvider theme={theme}>
                                    <Tooltip
                                        title={fieldToString(props.molecule.excelFileName)}
                                        placement="top"
                                        className="accordion-info-excel"
                                    >
                                        <div >{fieldToString(props.molecule.excelLine)}</div>
                                    </Tooltip>
                                </MuiThemeProvider>
                                
                            </div>
                        </div>
                    </>
                : null
                }
        </div>
    );
}
