import './PopUp.css'
import { IconButton } from '../IconButton/IconButton';
import { DeleteUserDetailsResponse } from '../../models/dtos/userDetailsRequestDto';
import {useState, useCallback, useMemo} from 'react';
import { DeleteUserDetailsRequest } from '../../models/dtos/deleteUserDetailsRequestDto';
import { UserDetailsService } from '../../services/UserDetailsService';
import { Loader } from '../loader/Loader';

interface IProps {
    userEmail: string;
    setShowPopup: (value: boolean) => void;
}

export function DeleteUserDetailsPopUp(props: IProps) {

    const [isLoading, setIsLoading] = useState(false);
    const [results, setResults] = useState(false);
    const [responseDeleteUserDetails, setResponseDeleteUserDetails] = useState<DeleteUserDetailsResponse>({
        success: false,
        n_deleted: 0
    });

    const service = useMemo(() => new UserDetailsService(), []);

    const deleteUserDetails = useCallback(async (userEmail: string) => {
        try{
            setIsLoading(true);
            var userToDelete : DeleteUserDetailsRequest = { userEmail: userEmail };
            service.deleteUserDetails(userToDelete).then(res => {
                setResponseDeleteUserDetails(res);
                setResults(true);
                setIsLoading(false);
            })
        }catch(err){
            setIsLoading(false);
        }
    },[setIsLoading, setResults, setResponseDeleteUserDetails, service]);

    return (
        <div className="popup-main">
            <div className="popup-title">Delete User Details</div>
            <div className="popup-separator-height"/>
            {isLoading ?
                <Loader/>
            :
                results ?
                <>
                    {responseDeleteUserDetails.success ? 
                        <div className="popup-message-text">Deleted {responseDeleteUserDetails.n_deleted} evaluation results from this user!</div>
                    :
                        <div className="popup-message-text">Error deleting user details!</div>
                    }
                    <div className="popup-separator-height"/>
                    <div className="popup-actions">
                        <IconButton className="iconbutton btn-white" text="Close" onClick={() => { props.setShowPopup(false) }} />
                    </div>
                </>
                :
                <>
                    <div className="popup-message-text">Are you sure you want to delete?</div>
                    <div className="popup-separator-height"/>
                    <div className="popup-actions">
                        <IconButton className="iconbutton btn-white" text="Cancel" onClick={() => { props.setShowPopup(false) }} />
                        <IconButton className="iconbutton btn-red" text="Delete" onClick={() => { deleteUserDetails(props.userEmail) }} />
                    </div>
                </>
            }
        </div>
    );
}