import CircularProgress from '@material-ui/core/CircularProgress';
import './Loader.css';

export function Loader() {
  return (
    <div className="loader-container">
        <CircularProgress />
    </div>

  );
}
