import './Accordion.css'
import { Loader } from '../loader/Loader';

interface IProps{
    message?: string;
    isLoading?: boolean;
}

export function AccordionEmpty(props: IProps){
    
    return(
        <div className="accordion-row">
            <div className="accordion-empty">
                {props.isLoading
                ?
                    <div><Loader /></div>
                :
                    <div>{props.message}</div>
                }
            </div>
        </div>
    );
}