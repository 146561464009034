import './PopUp.css'
import { IconButton } from '../IconButton/IconButton';
import { useState, useCallback } from 'react';
import { UserDetailsEntry } from '../../models/domain/UserDetailsEntry';
import { InputNumber } from '../InputNumber/InputNumber';
import { Checkbox } from '../Checkbox/Checkbox';

interface IProps {
    user: UserDetailsEntry;
    submitEdit: (value: UserDetailsEntry) => void;
    setShowPopup: (value: boolean) => void;
}

export function EditUserDetailsPopUp(props: IProps) {

    const [isValidated, setIsValidated] = useState(props.user.isValidated);
    const [maximumSubmissions, setMaximumSubmissions] = useState(props.user.maximumSubmissions.toString());
    const [errorMessageMS, setErrorMessageMS] = useState("");


    const validateForm = useCallback((maximumSubmissions: string) => {
        if(!maximumSubmissions){
            setErrorMessageMS("The maximum submissions field is mandatory!");
            return false;
        }
        else{
            setErrorMessageMS("");
            return true;
        }   
    }, [setErrorMessageMS]);


    const onSubmit = useCallback((isValid: boolean, maximumSubmissions: string) => {
        if(validateForm(maximumSubmissions)){
            var user : UserDetailsEntry = {
                email: props.user.email,
                isValidated: isValidated,
                maximumSubmissions: Number.parseFloat(maximumSubmissions),
                submissionsDone: props.user.submissionsDone
            };
            props.submitEdit(user);
            // props.setShowPopup(false);
        }
    }, [isValidated, validateForm, props]);

    return (
        <div className="popup-main" >
            <div className="popup-title">Edit User Details</div>
            <div className="popup-separator-height" />
            <div className="popup-message">
                <div className="input-text">
                    <p>Valid User</p>
                    {isValidated ?
                        <Checkbox isChecked={isValidated} onToogle={setIsValidated} text='Valid'/>
                    : 
                        <Checkbox isChecked={isValidated} onToogle={setIsValidated} text='Invalid'/>
                    }
                </div>
                <div className="input-text">
                    <p>Maximum Submissions *</p>
                    <InputNumber errorMessage={errorMessageMS} value={maximumSubmissions} /*label={"[x-x]"}*/ onChange={setMaximumSubmissions} />
                    {errorMessageMS
                        ? <h5 className="error-message">{errorMessageMS}</h5>
                        : null}
                </div>
            </div>
            <div className="popup-separator-height" />
            <div className="popup-actions">
                <IconButton className="iconbutton btn-white" text="Cancel" onClick={() => { props.setShowPopup(false) }} />
                <IconButton className="iconbutton btn-dark" text="Edit" onClick={() => { onSubmit(isValidated, maximumSubmissions) }} />
            </div>
        </div>
    );
}