
import { HttpClient } from '../Infrastructure/Http/HttpClient';
import { UrlUtility } from '../utils/UrlUtility';
import { ConfigurationProvider } from '../Infrastructure/Configuration/ConfigurationProvider';

export class GateService{

    isAdmin(): Promise<boolean> {
        return HttpClient.sessionRequest({
            method: "get",
            url: UrlUtility.join(ConfigurationProvider.getConfiguration().App.BackendUrl, "api/Users/isAdmin"),
        }).then((res)=>{
            return res.data.isAuthorized;
        });
    }
}
