import { useCallback, useMemo } from 'react';
import './ActionButton.css'


interface IProps{
    text: string;
    icon: any;
    color?: string;
    onClick: () => void;
    isDisable?: boolean;
}

export function ActionButton(props: IProps){

    const cssClasses = useMemo(() => {
        let css = "action-button";

        if(props.color && !props.isDisable){
            css = css + " " + props.color;
        }

        if(props.isDisable){
            css = css + " disable";
        }

        return css;
    }, [props.isDisable, props.color])

    const onClick = useCallback(() => {
        if(props.isDisable) return;

        if(props.onClick) props.onClick();

    },[props])

    return(
        <div className={cssClasses} onClick={() => onClick()}>
            <div className="icon" >{props.icon}</div>
            <div className="text">{props.text}</div>
        </div>
    )
}
