import './SubmissionForm.css'
import { useCallback, useEffect, useState } from 'react';
import { MoleculeEntry } from '../../../../models/domain/MoleculeEntry';
import { ContainerLayout } from '../../../../components/Container/ContainerLayout';
import { NewInput } from '../../../../components/Input/NewInput';
import { IconButton } from '../../../../components/IconButton/IconButton';
import { InputNumber } from '../../../../components/InputNumber/InputNumber';
import { useGlobalPopup } from '../../../../Infrastructure/UI/Hooks/useGlobalPopup';
import { MoleculeSubmissionHelpPopup } from '../../../../components/PopUps/MoleculeSubmissionHelpPopup';
import {SmilesValidator} from './SmilesValidator';
import { ReactComponent as HelpSVG } from '../../../../assets/svg/help-circle-outline.svg'
import { ActionButton } from '../../../../components/ActionButton/ActionButton';

interface IProps{
    errorMessage: string;
    addMolecule: (molecule: MoleculeEntry) => boolean;
    cleanErrorMessage: () => void;
    moleculeToEdit: MoleculeEntry | undefined;
    // deleteMolecule: (molecule: MoleculeEntry) => void;
    // setMoleculeToEdit: (molecule: MoleculeEntry) => void;
}

export function SubmissionForm(props: IProps){
    const [identifier, setIdentifier] = useState("");
    const [smiles, setSmiles] = useState("");
    const [melting, setMelting] = useState<string>("");
    const [enthalpy, setEnthalpy] = useState<string >("");
    const [water, setWater] = useState<string >("");
    const [ph, setPh] = useState<string >("");
    const [logP, setLogP] = useState<string >("");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [validate, setValidate] = useState<boolean>(false);

    const [isPhDisable, setIsPhDisable] = useState<boolean>(true);
    const [errorMessageIdentifier, setErrorMessageIdentifier] = useState("");
    const [errorMessageSmiles, setErrorMessageSmiles] = useState("");
    const [errorMessageMP, setErrorMessageMP] = useState("");
    const [errorMessageEF, setErrorMessageEF] = useState("");
    const [errorMessageWS, setErrorMessageWS] = useState("");
    const [errorMessagePH, setErrorMessagePH] = useState("");
    const [errorMessageLP, setErrorMessageLP] = useState("");

    const {setShowPopup, setPopupContent} = useGlobalPopup();

    const onInputChange = useCallback((event: any) => {
        //console.log("aqui crlj " + event);

        let regex = /\d+(\.\d*)?/g;
        let arr = regex.exec(event) || [""];
        let val = "" + event;
        if(val.charAt(0) === "-"){
            console.log("-" + arr[0]);
            return "-" + arr[0];
        } else {
            console.log(arr[0]);
            return arr[0];
        }
    },[])

    const validateFormNumber = useCallback(() => {
        let isValid = true;

        if(Number.parseFloat(onInputChange(melting)) <= 25){
            isValid = false;
            setErrorMessageMP("The MeltingPoint must be greater than 25.0°C");
        }else{
            setErrorMessageMP("");
        }

        if(Number.parseFloat(onInputChange(enthalpy)) <= 0){
            isValid = false;
            setErrorMessageEF("The Enthalpy of fusion must be greater than 0.0J/mol");
        }else{
            setErrorMessageEF("");
        }

        if(Number.parseFloat(onInputChange(water)) <= 0){
            isValid = false;
            setErrorMessageWS("The Water Solubility must be greater than 0.0mg/L");
        }else{
            setErrorMessageWS("")
        }

        if(Number.parseFloat(onInputChange(ph)) < 0 || Number.parseFloat(onInputChange(ph)) > 14){
            isValid = false;
            setErrorMessagePH("The Ph must be between [0.0, 14.0]");
        }else{
            setErrorMessagePH("")
        }

        if(Number.parseFloat(onInputChange(logP)) < -50 || Number.parseFloat(onInputChange(logP)) > 50){
            isValid = false;
            setErrorMessageLP("The LogP Solubility must be between [-50.0, 50.0]");
        }else{
            setErrorMessageLP("")
        }

        setValidate(isValid);

        return isValid;
    }, [melting, enthalpy, water,  ph, logP, setValidate, setErrorMessageMP, setErrorMessageEF, setErrorMessageWS, setErrorMessagePH, setErrorMessageLP, onInputChange]);


    const validateForm = useCallback(() => {
        let isValid = true;

        if(!identifier){
            isValid = false;
            props.cleanErrorMessage();
            setErrorMessageIdentifier("The Identifier field is mandatory!");
        }else{
            setErrorMessageIdentifier("")
        }

        if(!smiles){
            isValid = false;
            props.cleanErrorMessage()
            setErrorMessageSmiles("The SMILES field is mandatory!");
        }else if(SmilesValidator.checkMultipleSmiles(smiles)){
            isValid = false;
            props.cleanErrorMessage()
            setErrorMessageSmiles("This value appears to be a combination product, please submit it as separate SMILES!"); 
        }else if(!SmilesValidator.validateSmiles(smiles)){
            isValid = false;
            props.cleanErrorMessage()
            setErrorMessageSmiles("The SMILES field is invalid!"); 
        }else{
            setErrorMessageSmiles("")
        }

        if(!validateFormNumber()){
            isValid = false;
        }

        setValidate(isValid);

        return isValid;
    }, [identifier, smiles, setValidate, validateFormNumber, setErrorMessageSmiles, setErrorMessageIdentifier, props]);




    const parseFormValue = useCallback((value: string) => {
        if(value === ""){
            return undefined;
        }

        return Number.parseFloat(value);
    }, []);

    const cleanForm = useCallback(() => {
        setIdentifier("");
        setSmiles("");
        setMelting("");
        setEnthalpy("");
        setWater("");
        setPh("");
        setLogP("");
        setErrorMessageIdentifier("")
        setErrorMessageSmiles("")
    },[setIdentifier, setSmiles, setMelting,setEnthalpy, setWater, setPh, setLogP, setErrorMessageIdentifier, setErrorMessageSmiles])


    const add = useCallback(() => {
        if(validateForm()){
            var molecule: MoleculeEntry = {
                name: identifier,
                smiles: smiles,
                meltingPoint: parseFormValue(onInputChange(melting)),
                enthalpyOfFusion: parseFormValue(onInputChange(enthalpy)),
                waterSolubility: parseFormValue(onInputChange(water)),
                ph: parseFormValue(onInputChange(ph)),
                logP: parseFormValue(onInputChange(logP))
            }
            if(props.addMolecule(molecule)){
                cleanForm();
            }
        }
    },[identifier, melting, enthalpy, water, logP, smiles, ph, props, cleanForm, validateForm, onInputChange, parseFormValue])


    useEffect(() => {
        if(!water || water === "0" || water.length < 1 ){
            setPh("");
            setIsPhDisable(true);
        }
        else{
            setIsPhDisable(false);
        }
    },[water]);


    useEffect(() => {
        if(props.moleculeToEdit){
            setIdentifier(props.moleculeToEdit.name)
            setSmiles(props.moleculeToEdit.smiles)
            setMelting(props.moleculeToEdit.meltingPoint?.toString() || "")
            setEnthalpy(props.moleculeToEdit.enthalpyOfFusion?.toString() || "")
            setWater(props.moleculeToEdit.waterSolubility?.toString() || "")
            setPh(props.moleculeToEdit.ph?.toString() || "")
            setLogP(props.moleculeToEdit.logP?.toString() || "")
        }
    },[props.moleculeToEdit, setIdentifier, setSmiles, setMelting, setEnthalpy, setWater, setPh, setLogP]);


    return(
        <>
            <ContainerLayout className="flexgrow">
                <div className="submission-form">
                    <div className="actions">
                        <ActionButton text={"Help"} icon={<HelpSVG />} color={"blue"} onClick={()=>{
                            //needed for firefox scroll
                                var elem = document.getElementById('main-content');
                                if(elem) elem.style.overflowY = 'hidden';
                            setShowPopup(true)
                            setPopupContent(<MoleculeSubmissionHelpPopup setShowPopup={setShowPopup}/>);
                        }} />
                    </div>
                    <div className="submission-form-input">
                        <div className="input-text">
                            <p>Identifier *</p>
                            <NewInput type="text" errorMessage={errorMessageIdentifier} className="identifier-input" value={identifier} placeholder="Enter Identifier" onChange={(e) => setIdentifier(e.target.value)}/>
                            {errorMessageIdentifier
                                ? <h5 className="error-message">{errorMessageIdentifier}</h5>
                                : null
                            }
                            {props.errorMessage
                                ? <h5 className="error-message">{props.errorMessage}</h5>
                                : null
                            }
                        </div>
                        <div className="separator-inputs"/>
                        <div className="input-text">
                            <p>SMILES *</p>
                            <NewInput type="text" errorMessage={errorMessageSmiles} className="identifier-input" value={smiles} placeholder="SMILES" onChange={(e) => setSmiles(e.target.value)}/>
                            {errorMessageSmiles
                                ? <h5 className="error-message">{errorMessageSmiles}</h5>
                                : null
                            }
                        </div>
                    </div>
                    <div className="separator-inputs"/>
                    <div className="submission-form-input-number">
                        <div className="input-number-up">
                            <div className="input-text-up">
                                <p>Melting Point</p>
                                <InputNumber errorMessage={errorMessageMP} value={melting} label={" °C"} onChange={setMelting}/>
                                {errorMessageMP
                                ? <h5 className="error-message">{errorMessageMP}</h5>
                                : null
                            }
                            </div>
                            <div className="input-text-up">
                                <p>Enthalpy of Fusion</p>
                                <InputNumber  errorMessage={errorMessageEF} value={enthalpy} label={" J/mol"} onChange={setEnthalpy}/>
                                {errorMessageEF
                                ? <h5 className="error-message">{errorMessageEF}</h5>
                                : null
                            }
                            </div>
                        </div>
                        <div className="input-number-down">
                            <div className="input-text">
                                <p>Water Solubility</p>
                                <InputNumber errorMessage={errorMessageWS} value={water} label={" mg/L"} onChange={setWater}/>
                                {errorMessageWS
                                ? <h5 className="error-message">{errorMessageWS}</h5>
                                : null
                            }
                            </div>
                            <div className="input-text">
                                <p>pH</p>
                                <InputNumber  errorMessage={errorMessagePH} value={ph} isDisable={isPhDisable} onChange={setPh}/>
                                {errorMessagePH
                                ? <h5 className="error-message">{errorMessagePH}</h5>
                                : null
                            }
                            </div>
                            <div className="input-text">
                                <p>LogP</p>
                                <InputNumber  errorMessage={errorMessageLP} value={logP} onChange={setLogP}/>
                                {errorMessageLP
                                ? <h5 className="error-message">{errorMessageLP}</h5>
                                : null
                            }
                            </div>
                        </div>

                    </div>
                    <div className="separator-inputs"/>
                    <div className="form-actions">
                        <div className="actions">
                            <IconButton className="iconbutton btn-dark" text="Add" onClick={() => add()} />
                        </div>
                    </div>
                </div>
            </ContainerLayout>
        </>
    );
}
