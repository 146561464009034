import React from 'react';
import './ContainerLayout.css';

interface IContainerLayoutProps {
    children: React.ReactNode
    className?: string;
    id?: string;
}

export function ContainerLayout(props: IContainerLayoutProps){
    return (
        <div className={"container-layout " + (props.className || "")}>
            {props.children}
        </div>
    );
}