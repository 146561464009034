import './PopUp.css'
import { ReactComponent as CloseSVG } from '../../assets/svg/window-close.svg'

interface IProps{
    setShowPopup: (value: boolean) => void;
}

export function MoleculeSubmissionHelpPopup(props: IProps){

    return(
        <div className="popup-main" >
            <div className="popup-header">
                <div className="popup-title"><b>How to fill the STARMAP input template</b></div>
                <div className="popup-close"><CloseSVG className="popup-svg" onClick={() => {
                    //needed for firefox scroll
                    var elem = document.getElementById('main-content');
                    if(elem) elem.style.overflowY = 'scroll';

                    props.setShowPopup(false)
                }}/></div>
            </div>
            <div className="popup-separator-height"/>
            <div className="popup-message-large">
                <p>The following information is the minimum to perform the STARMAP evaluation of a compound. These two cells cannot be left blank:</p>
                <ul>
                    <li><b>Identifier: </b>This can be any string, for instance the common name, the codename or something like “Molecule 13”. The purpose is to have a unique identifier for each molecule, so this entry needs to be different for each compound.</li>
                    <b>When filling out the Unique Identifier field for your submission, please avoid introducing sensitive information.</b>
                    <li><b>SMILES: </b>Molecular structure encoded as a SMILES string (<b><a href="https://en.wikipedia.org/wiki/Simplified_molecular-input_line-entry_system">https://en.wikipedia.org/wiki/Simplified_molecular-input_line-entry_system</a></b>).
                        <br/>
                        <span className='popup-message-large-note'>NOTE:</span> To submit a combination product please insert two separate entries.
                    </li>
                    
                </ul>
                
                <p>The following experimental data are required for optimal predictions. If there are missing values, please leave the corresponding cells blank.</p>
                <ul>
                    <li><b>Melting temperature (C): </b>Melting temperature of the compound in degrees Celsius.<span> Must be greater than 25.0°C.</span> The compounds submitted for evaluation must be solid at room temperature.</li>
                    <li><b>Enthalpy of fusion (J/mol): </b>Enthalpy of fusion in Joule per mole.<span> Must be greater than 0.0 J/mol.</span></li>
                    <li><b>Solubility in water (mg/L): </b>Solubility of the compound in water, in units of mg/L. If the solubility has been measured in different buffers, please select the one closer to pH=7 and include the pH value in the appropriate column.<span> Must be greater than 0.0 mg/L.</span></li>
                    <li><b>pH of aqueous buffer (if applicable): </b>pH of the aqueous solution where solubility in water has been measured.<span> Valid range: [0.0, 14.0].</span></li>
                    <li><b>LogP: </b>Octanol/water partition coefficient.<span> Valid range: [-50.0, 50.0].</span></li>
                </ul>

                <p><b>If you are using the provided template to input data on multiple molecules:</b></p>
                <ul>
                    <li>Each row contains data related to single molecule.</li>
                    <li>For numerical columns (the optional ones) report only one number, no ranges.</li>
                    <li>Please, use the dot (.) as decimal separator, not the comma (,)</li>
                </ul>

                <p><b>General instructions:</b></p>
                <ul>
                    <li>No salts, please use the free form of the compound.</li>
                    <li>No mixture compounds, but the individual components can be evaluated individually.</li>
                </ul>                    
            </div>
        </div>
    );
}