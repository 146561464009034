import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IKeyLabel } from '../Table';
import {ReactComponent as ArrowSVG} from '../../../assets/svg/arrow_simple.svg';
import {ReactComponent as DoubleArrowSVG} from '../../../assets/svg/arrow_double.svg';
import './TableFooter.css';
import { ComboBox } from '../../ComboBox/ComboBox';

interface ITableFooterProps {
    paginationOptions: IKeyLabel[];
    totalItems: number;
    currentPage: number;
    itemsPage?: number;
    currentPageItemCount: number;
    onCurrentPageChanged?: (newPage: number) => void;
    onRowsPerPageChanged?: (rowsPerPage: number) => void;
}

interface IPaginationButtonProps {
    className?: string;
    isDisabled?: boolean;
    onClick?: () => void;
    children: React.ReactNode;
}

function PaginationButton(props: IPaginationButtonProps){

    const cssDisabled = useCallback((isDisabled: boolean|undefined) => {
        return isDisabled ? "disabled" : "";
    }, []);

    const handleClick = useCallback((_: any) => {
        if(!props.isDisabled && props.onClick){
            props.onClick();
        }
    }, [props]);

    return (
        <div className={["n4mp-square", (props.className || ""), cssDisabled(props.isDisabled)].join(" ")} onClick={handleClick}>
            {props.children}
        </div>
    );
}

export function TableFooter(props: ITableFooterProps){

    const [totalPages, setTotalPages] = useState(0);
    const [selectedLength, setSelectedLength] = useState<number>(props.paginationOptions[0].label);

    /* -----------------
    * Computed Values
    ----------------- */
    const itemRange = useMemo(() => {
        let first = selectedLength * props.currentPage;
        let second = first + props.currentPageItemCount;
        return (first+1) + '-' + second;
    }, [selectedLength, props.currentPage, props.currentPageItemCount])

    // const pageLength = useMemo(() => {
    //     return props.totalItems;
    // }, [props.totalItems])

    const hasNextPage = useMemo(() => {
        return props.currentPage < totalPages - 1;
    }, [props.currentPage, totalPages]);

    const hasPreviousPage = useMemo(() => {
        return props.currentPage > 0;
    }, [props.currentPage]);

    /* -----------------
    * Callbacks
    ----------------- */
    const onCurrentPageChanged = useCallback((newPage: number) => {
        if(props.onCurrentPageChanged)
            props.onCurrentPageChanged(newPage);
    }, [props]);


    const onRowsPerPageChanged = useCallback((key: any) => {
        let res = props.paginationOptions.filter(opt => opt.key === key);
        // var newPage = 0;
        if(res && res.length && props.onRowsPerPageChanged){
            props.onRowsPerPageChanged(res[0].label );
            setSelectedLength(res[0].label);
            onCurrentPageChanged(0);
        }
    }, [props, setSelectedLength, onCurrentPageChanged]);




    const cssDisable = useMemo(() => {
        if(props.totalItems < 1){
            return " disable"
        } else {
            return ""
        }
    }, [props.totalItems])

    useEffect(() => {
        setTotalPages( Math.ceil(props.totalItems / selectedLength));
        setSelectedLength(( selectedLength))
    }, [props.totalItems, selectedLength])


    return (
        <div className="n4mp-table-footer">
            <div className="n4mp-table-footer-area-items">
                Items per page
                <div className="n4mp-table-footer-separator-width"/>
                <ComboBox css={cssDisable} optionsList={props.paginationOptions} onChange={onRowsPerPageChanged} selectedOp={selectedLength}/>
            </div>
            <div className="n4mp-table-grow"></div>
            <div className="n4mp-table-footer-area">
                <div>{itemRange} of {props.totalItems}</div>
                <div className="n4mp-table-footer-pagination">
                    <div className="n4mp-table-footer-separator-width"/>
                    <PaginationButton isDisabled={!hasPreviousPage || props.totalItems < 1} className="reversed" onClick={() => onCurrentPageChanged(0)}> <DoubleArrowSVG/> </PaginationButton>
                    <div className="n4mp-table-footer-separator-width"/>
                    <PaginationButton isDisabled={!hasPreviousPage || props.totalItems < 1} className="reversed" onClick={() => onCurrentPageChanged(props.currentPage -1)}> <ArrowSVG/> </PaginationButton>
                    <div className="n4mp-table-footer-separator-width"/>
                    <PaginationButton isDisabled={!hasNextPage} onClick={() => onCurrentPageChanged(props.currentPage +1)}> <ArrowSVG/> </PaginationButton>
                    <div className="n4mp-table-footer-separator-width"/>
                    <PaginationButton isDisabled={!hasNextPage} onClick={() => onCurrentPageChanged(totalPages-1)}> <DoubleArrowSVG/> </PaginationButton>
                </div>
            </div>
        </div>
    );
}
