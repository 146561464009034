import { useCallback, useEffect, useState} from 'react';
import './App.css';
import {Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { ResultsPage } from './areas/results/results';
import { Header } from './components/Header/Header';

import { SideBar } from './components/SideBar/SideBar';
import { SubmitMoleculesPage } from './areas/submit-molecules/submit-molecules-page';
import { SubmissionsPage } from './areas/submissions/submissions-page';
import { useRenderGlobalPopup } from './Infrastructure/UI/Hooks/useGlobalPopup';
import { PopupContainer } from './Infrastructure/UI/Components/PopupContainer/PopupContainer';
import { useViewport } from './Infrastructure/UI/Hooks/useViewPort';
import { useRenderGlobalLoading, useGlobalLoading } from './Infrastructure/UI/Hooks/useGlobalLoading';
import { UsersPage } from './areas/users/users-page';
import { usePrivateGuard, usePrivateGuardAdmin } from './Infrastructure/Routing/RoutingGuards';
import { GuardedRoute, GuardProvider } from 'react-router-guards';
import { GateService } from './services/GateService';
import is from "is_js";

export function AppModule() {
    const[isOpen, setIsOpen] = useState<boolean>(false);
    const {isShowPopup, popupContent} = useRenderGlobalPopup();
    const {showFSL, FSLContent} = useRenderGlobalLoading();
    const {setShowFSL} = useGlobalLoading();
    const [isAdmin, setIsAdmin] = useState<boolean>(false)
    const {url} = useRouteMatch();
    const {width} = useViewport();
    const privateGuard = usePrivateGuard();
    const privateGuardAdmin = usePrivateGuardAdmin();

    useEffect(() => {
        let gateService = new GateService();
        gateService.isAdmin().then((res)=>{
            setIsAdmin(res);
            setShowFSL(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const withSidebar = useCallback(() => {
      if(width > 992){
        setIsOpen(false);
      }
    },[width, setIsOpen]);


    useEffect(() => {
      withSidebar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width]);


    return (
        <div className="main-content">
            <div className={`full-screen${(isShowPopup || showFSL) ? "" : "-firefox"}`}>

                <Header toGoSide={() => setIsOpen(!isOpen)} isOpen={isOpen} isAdmin={isAdmin}/>

                <div className="main-heading" onClick={() => setIsOpen(false)}>

                    <GuardProvider guards={[privateGuard]} >
                        <Switch>
                            <GuardedRoute path={`${url}/molecules`}><SubmitMoleculesPage /></GuardedRoute>
                            <GuardedRoute path={`${url}/submissions`}><SubmissionsPage /></GuardedRoute>
                            <GuardedRoute path={`${url}/results`}><ResultsPage/></GuardedRoute>
                            <GuardedRoute path={`${url}/admin`} guards={[privateGuardAdmin]}><UsersPage/></GuardedRoute>
                            <GuardedRoute path={`${url}/`}><Redirect to={`${url}/submissions`}></Redirect></GuardedRoute>
                        </Switch>
                    </GuardProvider>

                </div>
            </div>

            {isOpen ?
                <div className="overlay-sidebar" onClick={() => setIsOpen(!isOpen)}>
                    <SideBar isOpen={isOpen} isAdmin={isAdmin}/>
                </div>
            : null}

            {isShowPopup ? <PopupContainer>{popupContent}</PopupContainer> : null}

            {showFSL ? <>{FSLContent}</> : null}

        </div>
    );
}
