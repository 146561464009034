import { useContext } from "react";
import { UIContext } from "../UIServices";

export function useViewport() {

    const context = useContext(UIContext);
    if(!context){
        throw new Error("useViewport must be used within UIServices");
    }

    return {width: context.viewPortWidth, height: context.viewPortHeight }
}
