import { ITableColumn } from '../Table';
import './TableHeaderCell.css';

interface ITableHeaderCellProps<T> {
     column: ITableColumn<T>;
}


export function TableHeaderCell<T>(props: ITableHeaderCellProps<T>){

    return (
        <th className="n4mp-table-header-cell">{props.column.headerLabel}</th>
    );
}
