import { ReactNode, useEffect } from "react";
import {useMemo} from 'react';
import is from "is_js";

declare global {
    function particlesJS(arg0: string, arg1: any): void;
}


interface IMainBackgroundLayoutProps {
    children: ReactNode
}

export function MainBackgroundLayout(props: IMainBackgroundLayoutProps){

    useEffect( () => {
        particlesJS("particles-js", {
            "fps_limit": 30,
            "particles":{
                "number":{"value":180,"density":{"enable":true,"value_area":800}},
                "color":{"value":"#ffffff"},
                "shape":{"type":"circle","stroke":{"width":0,"color":"#000000"},"polygon":{"nb_sides":5},"image":{"src":"img/github.svg","width":100,"height":100}},
                "opacity":{"value":0.2,"random":false,"anim":{"enable":false,"speed":1,"opacity_min":0.1,"sync":false}},
                "size":{"value":2.5,"random":true,"anim":{"enable":false,"speed":40,"size_min":0.1,"sync":false}},
                "line_linked":{"enable":false,"distance":150,"color":"#ffffff","opacity":0.4,"width":1},
                "move":{"enable":true,"speed":0.15,"direction":"top-right","random":false,"straight":true,"out_mode":"out","bounce":false,"attract":{"enable":false,"rotateX":600,"rotateY":1200}}
            },
            "interactivity":{
                "detect_on":"canvas",
                "events":{"onhover":{"enable":false,"mode":"repulse"},"onclick":{"enable":false,"mode":"push"},"resize":true},
                "modes":{
                    "grab":{"distance":400,"line_linked":{"opacity":1}},
                    "bubble":{"distance":400,"size":40,"duration":2,"opacity":8,"speed":3},
                    "repulse":{"distance":200,"duration":0.4},
                    "push":{"particles_nb":4},
                    "remove":{"particles_nb":2}}
            },
            "retina_detect":true
        });
  }, []);

    const cssIsSafari = useMemo(()=>{
        let css = ["full", "app-content"];
        if(is.safari()){
            css.push("safari");
        }else if(is.firefox()){
            css.push("firefox");
        }
        return css.join(" ");
    },[])

    return (
        <>
        <div className="full app-background">
            <div className="full overlay"> </div>
            <div className="full gradient2 mix"></div>
            <div className="full gradient multiply"> </div>

            <div className="full overlay3"> </div>


            <div id="particles-js" className="full"></div>
        </div>


        <div className={cssIsSafari}>
        {props.children}
        </div>

        </>
    )

}
