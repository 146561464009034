import './PopUp.css'
import { IconButton } from '../IconButton/IconButton';
import { UploadFileStatus } from '../../areas/submit-molecules/submit-molecules-page';


interface IProps{
    message: string;
    status: UploadFileStatus
    setShowPopup: (value: boolean) => void;
}


export function UploadFilePopUp(props: IProps){
    return(
        <div className="popup-main" >
            <div className="popup-title">Upload Molecules</div>
            <div className="popup-separator-height"/>
            
            <div className="popup-message">{props.message}</div>
            
            {props.status.errors !== 0 || props.status.possibleDuplicates !== 0 || props.status.removedDuplicates !== 0
            ? 
                <>
                    <div className="popup-separator-height"/>
                    <div className="status-error">
                        <div className="error-line"><div className="red">   {props.status.errors}             </div><div> &nbsp; - Errors</div></div>
                        <div className="error-line"><div className="yellow">{props.status.possibleDuplicates} </div><div> &nbsp; - Potential Repeated Molecules</div></div>
                        <div className="error-line"><div className="white"> {props.status.removedDuplicates}  </div><div> &nbsp; - Equal Molecules Removed</div></div>
                    </div>
                    <div className="popup-separator-height"/>
                    <div className="popup-alert">Please check values before batch submission!</div>
                </>
            :
                null
            }

            <div className="popup-separator-height"/>
            <div className="popup-actions">
                <IconButton className="iconbutton btn-dark" text="Continue" onClick={() => {props.setShowPopup(false)}} />
            </div>
        </div>
    );
}