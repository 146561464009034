import { useCallback } from 'react';
import {UserDetailsService} from '../../services/UserDetailsService';
import { useIsAuthenticated } from '@azure/msal-react';
import { GateService } from '../../services/GateService';

export function usePrivateGuard() {

    const isAuthenticated = useIsAuthenticated();

    const privateGuard = useCallback(async (to: any, from: any, next: any) => {

        console.log('Private guard', { from, to });
        const usersService = new UserDetailsService();
        
        try{
            const res = await usersService.validateUserAccount();
            if (!isAuthenticated) {
                console.log("Private guard :: Not Authenticated");
                next.redirect('/Identity/SignIn'); //todo get back to the same page
            }
            else if (res.isValidated) {
                next();
            } else {
                next.redirect("/accountNotValidated");
            }
        }catch{
            next.redirect("/error?errorCode=503&message=Service Unavailable! Please try again later");
        }

    }, [isAuthenticated]);

    return privateGuard;
}

export function usePrivateGuardAdmin() {

    const isAuthenticated = useIsAuthenticated();

    const privateGuardAdmin = useCallback(async (to: any, from: any, next: any) => {

        console.log('Private guard Admin', { from, to });

        if (!isAuthenticated) {
            console.log("Private guard Admin :: Not Authenticated");
            next.redirect('/Identity/SignIn'); //todo get back to the same page
        }
        else {
            const gateService = new GateService();
            var isValid = false;
            await gateService.isAdmin()
                .then((res) => isValid = res)
                .catch((_)=>{
                });
            debugger;
            if (isValid) {
                next();
            } else {
                next.redirect("/error?errorCode=403&message=Access Denied! Your user does not have access to this page");
            }
        }
    }, [isAuthenticated]);

    return privateGuardAdmin;
}