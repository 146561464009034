import { ConfigurationProvider } from '../Infrastructure/Configuration/ConfigurationProvider';
import { HttpClient } from '../Infrastructure/Http/HttpClient';
import { AnalysisSubmissionSummaryRequestDTO } from '../models/api/analysis-submission-summary-request-dto';
import { AnalysisSubmissionSummaryResponseDTO } from '../models/api/analysis-submission-summary-response-dto';
import { AnalysisSubmissionSummary } from '../models/domain/AnalisysBatchSummary';
import { PagedResults } from '../models/domain/PagedResults';
import { UrlUtility } from '../utils/UrlUtility';
import { ResultReportRequestDTO } from '../models/api/result-report-request-dto';
import { GetMaximumNumberOfSubmissionsResponseDTO, ResultReportResponseDTO } from '../models/api/result-report-respons-dto';


export class SubmissionsService {

    getSubmissionsSummary(requestQuery?: AnalysisSubmissionSummaryRequestDTO): Promise<PagedResults<AnalysisSubmissionSummary>> {
        return HttpClient.sessionRequest<AnalysisSubmissionSummaryResponseDTO>({
            method: 'GET',
            url: UrlUtility.join(ConfigurationProvider.getConfiguration().App.BackendUrl, "api/Submissions"),
            params: UrlUtility.stripUndefinedProps(requestQuery)
        }).then(res => {
            let response: PagedResults<AnalysisSubmissionSummary> = {
                total: res.data.total,
                records: res.data.analysisSubmissions.map((r):AnalysisSubmissionSummary => ({
                    batchId: r.batchId,
                    id:r.id,
                    molecules: r.molecules,
                    status: r.status,
                    statusLastUpdated: r.statusLastUpdated,
                    submissionTimeStamp: r.submissionTimeStamp,
                    batchDescription: r.batchDescription
                }))
            };
            return response;
        })
    }

    getResultsForBatches(requestQuery: ResultReportRequestDTO): Promise<ResultReportResponseDTO> {
        return HttpClient.sessionRequest<ResultReportResponseDTO>({
            method: 'POST',
            url: UrlUtility.join(ConfigurationProvider.getConfiguration().App.BackendUrl, "api/Submissions/ReportGenerator"),
            data: requestQuery
        }).then(res => {
            return res.data;
        })
    }

    getMaximumNumberOfSubmissions() : Promise<GetMaximumNumberOfSubmissionsResponseDTO>{
        return HttpClient.sessionRequest<GetMaximumNumberOfSubmissionsResponseDTO>({
            method: 'GET',
            url: UrlUtility.join(ConfigurationProvider.getConfiguration().App.BackendUrl, "api/Submissions/MaximumNumberOfSubmissions"),
        }).then(res => {
            return res.data;
        })
    }
}