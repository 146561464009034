import React, { useCallback, useMemo } from 'react';
import { Loader } from '../loader/Loader';
import './IconButton.css';

export enum IconButtonType {
    PRIMARY,
    ACCENT,
    SECONDARY,
    WARN
}

// const cssClassForType = (type: IconButtonType) => {
//     switch(type){
//         case IconButtonType.PRIMARY: return "iconbutton-primary";
//         case IconButtonType.SECONDARY: return "iconbutton-secondary";
//         case IconButtonType.ACCENT: return "iconbutton-accent";

//         default: return "iconbutton-primary";
//     }
// };

export interface IIconButtonProps{
    text?: any;
    icon?: React.ReactNode;
    id?: any;
    className?: string;
    isDisable?: boolean;
    type?: IconButtonType;
    href?: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    isLoading?: boolean;
}

export function IconButton(props: IIconButtonProps){

    const cssClasses = useMemo(() => {
        let css = "iconbutton";

        if(props.className){
            css = css + " " + props.className;
        }

        if(props.isDisable){
            css = css + " disable";
        }

        return css;
    }, [props.isDisable, props.className])


    const onClick = useCallback((e) => {
        if(props.isDisable) return;

        if(props.onClick) props.onClick(e);

    },[props])

    return(
        <>
            {props.href ?
                <a className={cssClasses} href={props.href} rel="noopener noreferrer" target="_blank">
                    <div id={props.id}>

                        <div className="iconbutton-content">
                            {props.isLoading ?
                                <Loader />
                                :
                                <>{props.icon
                                    ?
                                    <div className="btn-icon">{props.icon}</div>
                                    :
                                    null}
                                    <div className="btn-text">{props.text}</div>
                                </>
                            }
                        </div>
                    </div>
                </a>
            :
                <div id={props.id} className={cssClasses} onClick={(e) => onClick(e)}>
                    <div className="iconbutton-content">
                        {props.isLoading ?
                            <Loader />
                        :
                            <>{props.icon
                            ?
                                <div className="btn-icon">{props.icon}</div>
                            :
                                null}
                            <div className="btn-text">{props.text}</div>
                            </>
                        }
                    </div>
                </div>
            }
        </>
    )
}

/****************** OK *******************/
