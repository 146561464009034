import { useCallback, useEffect, useMemo, useState } from 'react';
import './users-page.css';
import { UserDetailsEntry } from '../../models/domain/UserDetailsEntry';
import { UserDetailsService } from '../../services/UserDetailsService';
import { UsersList } from './components/UsersList/UsersList';
import { useGlobalPopup } from '../../Infrastructure/UI/Hooks/useGlobalPopup';
import { EditUserDetailsPopUp } from '../../components/PopUps/EditUserDetailsPopUp';
import { UpdateUserDetailsRequest } from '../../models/dtos/updateUserDetailsRequestDto';
import { UserDetailsRequest } from '../../models/dtos/userDetailsRequestDto';
import { DeleteUserDetailsPopUp } from '../../components/PopUps/DeleteUserDetailsPopup';
import { DeleteUserDetailsRequest } from '../../models/dtos/deleteUserDetailsRequestDto';

export function UsersPage() {
    const [usersList, setUsersList] = useState<UserDetailsEntry[]>([]);
    const [searchWord, setSearchWord] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);
    const {setShowPopup, setPopupContent} = useGlobalPopup();

    const [pageLength, setPageLength] = useState(10);
    const [actualPage, setActualPage] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    const service = useMemo(() => new UserDetailsService(), []);

    const loadUsersDetails = useCallback(() => {
        setIsLoading(true);

        setUsersList([]);
        setTotalRows(0);
        let request: UserDetailsRequest ={
            pageLength: pageLength,
            currentPage: actualPage,
            emailSearch: searchWord
        }

        service.getUserDetails(request).then(res => {
            setUsersList(res.records);
            setTotalRows(res.total);
            setIsLoading(false);
        }).catch(()=>{
            setIsLoading(false);
        });
    },[setIsLoading, setUsersList, setTotalRows, searchWord, pageLength, actualPage, service])

    useEffect(() => {
        loadUsersDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[searchWord, actualPage, pageLength])

    const submitEditUserDetails = useCallback(async (user: UserDetailsEntry) => {
        try{
            setIsLoading(true);
            setShowPopup(false);
            var userToUpdate : UpdateUserDetailsRequest = user;
            service.updateUserDetails(userToUpdate).then(_ => {
                loadUsersDetails();
            })
        }catch(err){
            setIsLoading(false);
            //TODO pop up fail
        }
    },[setIsLoading, setShowPopup, service, loadUsersDetails]);

    const editUserDetails = useCallback((user: UserDetailsEntry) => {
        try{
            setShowPopup(true)
            setPopupContent(<EditUserDetailsPopUp user={user} setShowPopup={setShowPopup} submitEdit={submitEditUserDetails}/>);
        }catch(err){
            //TODO pop up fail
            //setShowPopup(false);
        }
    },[setShowPopup, setPopupContent, submitEditUserDetails]);

    const handleDeleteUserDetails = useCallback((userEmail: string)=>{
        try{
            setShowPopup(true)
            setPopupContent(<DeleteUserDetailsPopUp userEmail={userEmail} setShowPopup={setShowPopup}/>);
        }catch(err){
            //TODO pop up fail
            //setShowPopup(false);
        }
    },[setShowPopup, setPopupContent,])

    return (
        <div className="page users-page">
            <div className="page-content users-page-content">
                <UsersList
                    isLoading={isLoading}
                    usersList={usersList}
                    actualPage={actualPage}
                    pageLength={pageLength}
                    totalRows={totalRows}
                    onCurrentPageChanged={setActualPage}
                    onRowsPerPageChange={setPageLength}
                    onEdit={editUserDetails}
                    onDelete={handleDeleteUserDetails}
                    onSearch={setSearchWord}/>
            </div>
        </div>
    )
}
