import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { ReactNode, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { IdentityService } from '../IdentityService';

interface IIdentityRouteProps {
    path: string;
    exec: (service: IdentityService) => void;
    ifAuthenticated?: ReactNode;
    component?: ReactNode;
}
export function IdentityRoute(props:IIdentityRouteProps){
    const isAuthenticated = useIsAuthenticated();
    return (
        <Route path={props.path} component={() => {
            if(isAuthenticated && props.ifAuthenticated){
                return <>{props.ifAuthenticated}</>
            }
            return <IdentityInteraction {...props} />
        }} />
    );
}
export function IdentityInteraction(props: IIdentityRouteProps){
    const {instance} = useMsal();
    useEffect(() => {
        let identityService = new IdentityService(instance);
        props.exec(identityService);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return <> {props.component || null} </>;
}
