import './button-submit.css';

interface ButtonSubmitProps{
    text: string,
    classNames?:string,
    color?:string,
    onClick : () => void,
}

export function ButtonSubmit(props: ButtonSubmitProps){

    return(
        <div className={`btn-submit-container ${props.classNames}`} onClick={() => props.onClick()}>
            <button className={props.color}>
                {props.text}
            </button>    
        </div>
    );

}