import '../InputNumber/InputNumber.css';
import { Input } from '../Input/Input';
import { ReactComponent as ArrowSVG } from '../../assets/svg/arrow.svg';
import { useCallback, useEffect, useMemo, useState } from 'react';

interface IProps {
    value: string | undefined;
    label?: string;
    isDisable?: boolean;
    placeholder?: string;
    errorMessage?: string;
    onChange: (value: string ) => void;
}

export function InputNumber(props: IProps){

    const [inputValue, setInputValue] = useState<string|undefined>(props.value || undefined);
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const updateOutputValue = useCallback((inputValue) => {
        let value = inputValue === undefined || inputValue === "" ? undefined : inputValue;

            console.log("inputValue changed" + props.value);
            if(value !== props.value){
                props.onChange(value);
            }
    }, [props] )

    const onUpClick = useCallback((value: any) => {
        if(props.isDisable){
            return;
        }
        setIsFocused(true);

        if(value){
            var number =  value;
            number++;
            setInputValue("" + number);
            updateOutputValue("" + number);
        } else {
        setInputValue("1");
        updateOutputValue("1");
        }
        setIsFocused(false);
    },
    [props.isDisable, setInputValue, setIsFocused, updateOutputValue])

    const onDownClick = useCallback((value: any) => {
        if(props.isDisable){
            return;
        }
        setIsFocused(true);
       if(value){
        var number =  value;
        number--;
        setInputValue("" + number);
        updateOutputValue("" + number);
       } else {
        setInputValue("-1");
        updateOutputValue("-1");

       }

       setIsFocused(false);
    },
    [props.isDisable, setInputValue, setIsFocused, updateOutputValue])

    const onInputChange = useCallback((event: any) => {
        setInputValue(event.target.value);
    },
    [])



    const cssClasses = useMemo(() => {
        let css = "";

        if(props.isDisable){
            css = "input-costum" + (isFocused ? " active" : "") + " disabled";
        }
        else{
            css = "input-costum" + (isFocused ? " active" : "") + (props.errorMessage ? " error" : "");
        }

        return css;
    }, [isFocused, props.isDisable, props.errorMessage])



    useEffect(() => {

        let value = props.value === undefined ? undefined : ""+ props.value;
        if(value !== inputValue)
            setInputValue(value)

        console.log("props.value changed " + props.value + " -> " + value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.value]);





    useEffect(() => {
        updateOutputValue(inputValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isFocused]);



    return(
        <>
            <div className={cssClasses}>
                <div className="values-input-number">
                    <Input type="text" disable={props.isDisable} className="info-input" value={inputValue}
                        onChange={onInputChange} onBlur={() => setIsFocused(false)} onFocus={() => setIsFocused(true)}
                    />
                    <div>{props.label}</div>
                </div>
                <div className="input-arrow-selectors">
                    <div className="click-selectors" onClick={() => onUpClick(inputValue)}>
                        <ArrowSVG className="selector-image-up" />
                    </div>
                    <div className="click-selectors" onClick={() => onDownClick(inputValue)}>
                        <ArrowSVG className="selector-image-down" />
                    </div>
                </div>
            </div>
        </>
    );
}
