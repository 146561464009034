import { ConfigurationProvider } from "../Infrastructure/Configuration/ConfigurationProvider";

export class Configuration {

    static isDev() {
        return ConfigurationProvider.getConfiguration().App.IsDev;
    }

    static StarMapAPIBaseUrl() {
        return ConfigurationProvider.getConfiguration().App.BackendUrl;
      }

    static baseUrl() {
        return ConfigurationProvider.getConfiguration().App.BaseUrl;
    }

    static backendUrl() {
        return ConfigurationProvider.getConfiguration().App.BackendUrl;
    }

}
