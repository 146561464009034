import "./SideBar.css";
import { ReactComponent as UserSvg } from '../../assets/svg/user.svg';
import { ReactComponent as LockSvg } from '../../assets/svg/lock.svg';
import { HeaderButton } from '../Header/components/Button/HeaderButton';
import { useHistory } from 'react-router-dom';
import { ReactComponent as LogoutSvg } from '../../assets/svg/logout.svg';
import { useIdentityUserEmail } from '../../Infrastructure/Identity/Hooks/useIdentityUserEmail';
import { useIdentityUserDisplayName } from '../../Infrastructure/Identity/Hooks/useIdentityUserDisplayName';
import Tooltip from "@mui/material/Tooltip";

interface IProps{
    isOpen?: boolean;
    isAdmin?: boolean;
}

export function SideBar(props: IProps){

    let history = useHistory();
    const {userDisplayName, userDisplayNameIsLoading} = useIdentityUserDisplayName();
    const {userEmail, userEmailIsLoading} = useIdentityUserEmail();

    return(
        <div className="sidebar-content">
            {userEmailIsLoading && userDisplayNameIsLoading ? null :
            <>
            <div className="sidebar-user-header">
                <div className="sidebar-user-header-svg"><UserSvg height="35px"/></div>
                <div className="separator-width"/>
                <div className="sidebar-user-info">
                    <div className="sidebar-user-name">{userDisplayName}</div>
                    <Tooltip title={userEmail || ""} placement="bottom-start">
                        <div className="sidebar-user-email">{userEmail}</div>
                    </Tooltip>
                </div>
            </div>
            <div className="sidebar-spacer"/>
            <div className="sidebar-separator-user"/>
            <div className="sidebar-spacer"/>
            <div className="sidebar-actions">
                {props.isAdmin?
                    <>
                        <div className="admin-option" onClick={() => { history.push("/app/admin"); } }>
                            <HeaderButton text="Admin Dashboard" className="sidebar-button" />
                            <div className="lockSVG-sidebar">
                                <LockSvg />
                            </div>
                        </div>
                        <div className="sidebar-spacer-actions"/>
                    </>
                :null}
                <HeaderButton text="Submit Analysis" className="sidebar-button" onClick={() => {history.push("/app/molecules")}}/>
                <div className="sidebar-spacer-actions"/>
                <HeaderButton text="My submissions" className="sidebar-button" onClick={() => {history.push("/app/submissions")}}/>
            </div>
            <div className="sidebar-spacer"/>
            <div className="sidebar-separator-user"/>
            <div className="sidebar-spacer"/>
            <div className="options-cards">
                <div className="sidebar-options-info" onClick={() => history.push("/Identity/Signout")}>
                    <div className="option-icon-sidebar">{<LogoutSvg/>}</div>
                    <div className="option-text-sidebar">Logout</div>
                </div>
            </div>
            </>}
        </div>
    );
}
