import React from 'react';
import { TableRow } from './TableRow/TableRow';
import { TableHeaderCell } from './TableHeaderCell/TableHeaderCell';
import './Table.css';
import { TableFooter } from './TableFooter/TableFooter';
import { ConditionalRender } from '../Directives/ConditionalRender';
import { Loader } from '../loader/Loader';
import ReactTooltip from "react-tooltip";
import logo from '../../assets/png/starmap-v2-1024x227 45.png';

export interface ITableColumn<T> {
    key: string;
    headerLabel: string | any;
    sortable: boolean;
    valueTransform?: (value: T) => React.ReactNode;
    cellClassNames?: string | ((value: T) => string);
    isHidden?: boolean;
}

export interface IKeyLabel{
    key: number
    label: number
}

interface ITableProps<T> {
    dataList: T[];
    dataTotalItems: number;
    columnDefinition: ITableColumn<T>[];
    paginationOptions?: IKeyLabel[];
    currentPage?: number;
    itemsPage?: number;
    isLoading?: boolean;
    className?: string;
    watermark?: boolean;
    onCurrentPageChanged?: (newPage: number) => void;
    onRowsPerPageChange?: (rowsPerPage: number) => void;
}

export function Table<T>(props: ITableProps<T>){
    return (
        <div className={"n4mp-table " + (props.className || "")}>
            <table>
                <thead>
                    <tr>
                        {props.columnDefinition.filter(r => !(r.isHidden)).map((col, idx) => <TableHeaderCell key={idx} column={col}/>)}
                    </tr>
                </thead>
                <tbody>
                    {props.dataList?.map((row, ridx) =>
                        <TableRow key={ridx} item={row} columnDefinitions={props.columnDefinition?.filter(r => !(r.isHidden))} />)
                    }
                   
                    {props.dataList?.length < 1
                    ?  
                        <tr >
                            {props.isLoading
                            ?
                                <td id="emptyTable" colSpan={props.columnDefinition.filter(r => !(r.isHidden)).length}>
                                    <Loader />
                                </td>
                            :
                                <td id="emptyTable" colSpan={props.columnDefinition.filter(r => !(r.isHidden)).length}>No Data</td>
                            }
                        </tr>
                    :
                        null}
                </tbody>

                <ConditionalRender if={props.paginationOptions && !!props.paginationOptions.length}>
                    <tfoot>
                        <tr>
                            <td className="n4mp-table-cell-reset" colSpan={props.columnDefinition.filter(r => !(r.isHidden)).length}>
                                <TableFooter
                                    totalItems={props.dataTotalItems}
                                    paginationOptions={props.paginationOptions || []}
                                    currentPage={props.currentPage || 0}
                                    currentPageItemCount={props.dataList?.length}
                                    itemsPage={props.itemsPage}
                                    onCurrentPageChanged={props.onCurrentPageChanged}
                                    onRowsPerPageChanged={props.onRowsPerPageChange}
                                />
                            </td>
                        </tr>
                    </tfoot>
                </ConditionalRender>
            </table>
        </div>
    );
}