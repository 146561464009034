import { IconButton } from '../../components/IconButton/IconButton';
import logo1 from '../../assets/png/starmap-v2-1024x227.png';
import logo2 from '../../assets/svg/nanoformLogo.svg';
import { useHistory } from 'react-router-dom';
import "./landing-page.css";

export function LandingPage(){

    const history = useHistory();

    return (
        <>
            <div className="landing-content">
                <div className="landing-header-logos">
                    <img className="landing-header-logo1" src={logo1} alt="starmap"/>
                    <div className='landing-header-division'></div>
                    <img className="landing-header-logo2"  src={logo2} alt="starmap"/>
                </div>
                <div className="landing-header">
                    <h1 className="landing-heading">
                        <span>Illuminating</span> the future <br/> of <span>Pharma</span>
                    </h1>
                </div>


                <div className="landing-actions" id="test">

                <IconButton className="iconbutton btn-green" text="Login" onClick={() => {history.push("/Identity/SignIn")}} />
                <IconButton className="iconbutton btn-white" text="Request Access" onClick={() => {history.push("/Identity/SignUp")}} />
                </div>
            </div>
        </>
    )
}
