import React, {createContext, useEffect, useState} from 'react';


export interface UIContextState{
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  viewPortWidth: number;
  viewPortHeight: number;
  showPopup: boolean;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  popupContent: React.ReactNode | undefined | null;
  setPopupContent: React.Dispatch<React.SetStateAction<React.ReactNode | undefined | null>>;

  showFSL: boolean;
  setShowFSL: React.Dispatch<React.SetStateAction<boolean>>;
  FSLContent: React.ReactNode | undefined | null;
  setFSLContent: React.Dispatch<React.SetStateAction<React.ReactNode | undefined | null>>;
}

export const UIContext = createContext<UIContextState|null>(null);
// export const UIContext = createContext<UIContextState|null>({
//     isLoading: false,
//     setIsLoading: undefined,
//     viewPortWidth: 0,
//     viewPortHeight: 0,
//     showPopup: false,
//     popupContent: null,
    
// });


interface IUIServicesProps{
  children: React.ReactNode;
}

export function UIServices({children}: IUIServicesProps) {
    const [isLoading, setIsLoading] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const [showPopup, setShowPopup] = useState(false);
    const [popupContent, setPopupContent] = useState<React.ReactNode | undefined | null>(null);

    const [showFSL, setShowFSL] = useState(false)
    const [FSLContent, setFSLContent] = useState<React.ReactNode | undefined | null>(null);

    useEffect(() => {
      const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
      }
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);


    const value = {isLoading, setIsLoading, viewPortWidth: width, viewPortHeight:height, showPopup, setShowPopup, popupContent, setPopupContent,
      showFSL, setShowFSL, FSLContent, setFSLContent};


    return (
      <UIContext.Provider value={value}> {children} </UIContext.Provider>
    )
}
