import { HttpClient } from '../Infrastructure/Http/HttpClient';
import { UrlUtility } from '../utils/UrlUtility';
import { ConfigurationProvider } from '../Infrastructure/Configuration/ConfigurationProvider';
import { UserDetailsEntry } from '../models/domain/UserDetailsEntry';
import { ValidateUserAccountRequest, UserDetailsRequest, DeleteUserDetailsResponse } from '../models/dtos/userDetailsRequestDto';
import { UpdateUserDetailsRequest } from '../models/dtos/updateUserDetailsRequestDto';
import { PagedResults } from '../models/domain/PagedResults';
import { DeleteUserDetailsRequest } from '../models/dtos/deleteUserDetailsRequestDto';

export class UserDetailsService {

    getUserDetails(request: UserDetailsRequest): Promise<PagedResults<UserDetailsEntry>> {
        return HttpClient.sessionRequest({
            method: 'GET',
            url: UrlUtility.join(ConfigurationProvider.getConfiguration().App.BackendUrl, "api/Users"),
            params: UrlUtility.stripUndefinedProps(request)
        }).then(res => res.data)
    }

    updateUserDetails(userToUpdate: UpdateUserDetailsRequest): Promise<any> {
        return HttpClient.sessionRequest({
            method: 'PUT',
            url: UrlUtility.join(ConfigurationProvider.getConfiguration().App.BackendUrl, "api/Users/UpdateUserData"),
            data: userToUpdate
        }).then(res => res.data)
    }

    validateUserAccount(): Promise<ValidateUserAccountRequest> {
        return HttpClient.sessionRequest({
            method: 'GET',
            url: UrlUtility.join(ConfigurationProvider.getConfiguration().App.BackendUrl, "api/Users/ValidateAccount"),
        }).then(res => res.data)
    }

    deleteUserDetails(userToDelete: DeleteUserDetailsRequest): Promise<DeleteUserDetailsResponse> {
        return HttpClient.sessionRequest({
            method: 'POST',
            url: UrlUtility.join(ConfigurationProvider.getConfiguration().App.BackendUrl, "api/Users/DeleteUserInfo"),
            data: userToDelete
        }).then(res => res.data)
    }

}