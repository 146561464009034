import { ReactComponent as CloseSVG } from '../../assets/svg/close-circle-outline.svg';
import './ResultsPopUp.css'
import { ConditionalRender } from '../Directives/ConditionalRender';
import { IMoleculeResult } from '../../models/result-matrix-element';

interface IProps{
    showResultsPopUp: boolean;
    setShowResultsPopUp: (flag: boolean) => void;
    bucketContent: IMoleculeResult[];
}

export function ResultsPopUp(props: IProps){
    
    return(
        <ConditionalRender if={props.showResultsPopUp}>
            <div className="popup-main">
                <div className="result-popup">
                    <div className="result-popup-header">
                        <div className="result-popup-header-title">
                            <div>Solubility: {props.bucketContent[0]?.solubility}</div>
                            <div>Propensity to crystallize: {props.bucketContent[0]?.propensity_to_crystallize}</div>
                        </div>
                        <CloseSVG className="result-popup-header-actions" onClick={() => props.setShowResultsPopUp(false)} />
                    </div>

                    <div className="popup-separatorHeight" />
                    <div className="popup-separator" />
                    <div className="popup-separatorHeight" />

                    <div className="popup-message">
                        {props.bucketContent.map((t,id) => (<div key={id} className="popup-drugName"><li>{t.name}</li></div>) )}
                    </div>
                    
                </div>
            </div>
        </ConditionalRender>
    );
}