import {ReactComponent as LogoutSvg} from '../../../../assets/svg/logout.svg';
import {ReactComponent as UserSvg} from '../../../../assets/svg/user.svg';
import './DropdownMenu.css'
import { useIdentityUserDisplayName } from '../../../../Infrastructure/Identity/Hooks/useIdentityUserDisplayName';
import { useIdentityUserEmail } from '../../../../Infrastructure/Identity/Hooks/useIdentityUserEmail';

interface IProps{
    handleClickSettings: (option: string) => void;
    handleClickLogout: (option: string) => void;
    setIsOpen: (isOpen: boolean) => void;
}

export function DropdownMenu(props: IProps) {

    const {userDisplayName, userDisplayNameIsLoading} = useIdentityUserDisplayName();
    const {userEmail, userEmailIsLoading} = useIdentityUserEmail();

    return (
       <>
       <div className="full-screen-panel" onClick={() => props.setIsOpen(false)}>
       {userEmailIsLoading && userDisplayNameIsLoading ? null :
        // <div className={cssContentUser} onMouseLeave={() => props.setIsOpen(false)}>
        <div className="content-user" onMouseLeave={() => props.setIsOpen(false)}>
            <div className="user-header">
                <div className="user-header-svg">
                    <UserSvg/>
                </div>
                <div className="user-info">
                    <div className="user-name">{userDisplayName}</div>
                    <div className="user-email">{userEmail}</div>
                </div>
            </div>
            <div className="separator-user"/>
            <div className="options-cards">
                {/* <div className="options-info" onClick={() => props.handleClickSettings("definicoes")}>
                    <div className="option-icon">{<SettingsSvg/>}</div>
                    <div className="option-text">Settings</div>
                </div> */}
                {/* <div className={cssOptionsInfo} onClick={() => props.handleClickLogout("logout")}> */}
                <div className="options-info" onClick={() => props.handleClickLogout("logout")}>
                    <div className="option-icon">{<LogoutSvg/>}</div>
                    <div className="option-text">Logout</div>
                </div>
            </div>
        </div>
}
        </div>
       </>
    );
}
