import { ConfigurationProvider } from './Configuration/ConfigurationProvider';
import { IdentityProvider } from './Identity/IdentityProvider';

export class Startup {

    static async Run( app: () => void) {

        await ConfigurationProvider.init();
        
        await Promise.all([
            IdentityProvider.init()
        ]);

        app();
    }

}
