import { ReactComponent as MenuDown} from '../../assets/svg/menu-down.svg'
import { ReactComponent as MenuUp} from '../../assets/svg/menu-up.svg'
import { ReactComponent as EditSVG } from '../../assets/svg/edit-icon.svg';
import { ReactComponent as RemoveSVG } from '../../assets/svg/close-circle-outline.svg';
import './Accordion.css'
import { useState } from 'react';
import { UserDetailsEntry } from '../../models/domain/UserDetailsEntry';


interface IProps{
    user: UserDetailsEntry;
    onEdit: (user: UserDetailsEntry) => void;
    onDelete: (email: string) => void;
}

export function AccordionRowUserDetails(props: IProps){

    const[isOpen, setIsOpen] = useState(false);

    return(
        <div className="accordion-row">
            <div className="accordion-row-close">
                <div className="accordion-title">{props.user.email}</div>
                    <div className="accordion-actions">
                        {isOpen ?
                            <MenuUp className="accordion-svg" onClick={() => setIsOpen(false)}/>
                        :
                            <MenuDown className="accordion-svg" onClick={() => setIsOpen(true)}/>
                        }
                        <div className="accordion-svg-spacer" />
                        <EditSVG className="accordion-svg" onClick={() => props.onEdit(props.user)}/>
                        <div className="accordion-svg-spacer" />
                        <RemoveSVG className="accordion-svg" onClick={() => props.onDelete(props.user.email)}/>
                    </div>
                </div>

                { isOpen ?
                    <>
                        <div className="accordion-separator-height" />
                        <div className="accordion-row-open">
                            <div className="accordion-info">
                                <div>Valid User</div>
                                <div>{props.user.isValidated ? "valid": "invalid"}</div>
                            </div>
                            <div className="accordion-info">
                                <div>Maximum Submissions</div>
                                <div>{props.user.maximumSubmissions}</div>
                            </div>
                            <div className="accordion-info">
                                <div>Submissions Done</div>
                                <div>{props.user.submissionsDone}</div>
                            </div>
                        </div>
                    </>
                : null
                }
        </div>
    );
}
