import {useRef, useCallback, useMemo} from 'react';
import { ButtonSubmit } from '../button-submit/button-submit';
import * as XLSX from 'xlsx';
import { MoleculeEntry } from '../../models/domain/MoleculeEntry';
import './input-file.css';
import { useGlobalLoading } from '../../Infrastructure/UI/Hooks/useGlobalLoading';
import { FullScreenLoader } from '../../Infrastructure/UI/Components/FullScreenLoader/FullScreenLoader';

interface IProp {
    name?: string,
    loadData: (data: any) => void,
    //onLoading?: (isLoading: boolean) => void,
    placeholder?: string,
    marginTop?: number,
    error?: string,
}

export function InputFile(props:IProp){
    const {setShowFSL, setFSLContent} = useGlobalLoading();
    const divRef = useRef<HTMLInputElement>(null);

    const style = useMemo(()=>{
        return ({ marginTop: `${props.marginTop ? props.marginTop + `rem` : null}`, })
    },[props.marginTop]);

    const replaceComma = useCallback((data : XLSX.WorkSheet) => {
        data['!rows']?.map(e=>console.log(e))
        
        console.log(data)
        var replacedData=data.replace(',','#;#');
        return replacedData;
    },[]);

    const extractValueFromCell = (cell: string) => {
        let cellValue = cell;
        if(cell.indexOf(',') > -1){
            cellValue = cellValue.substr(1, cellValue.length-2)
        }
        return cellValue.replace(/""/g, '"');
    }
    const convertToMoleculesArray = useCallback((csv: any, fileName: string): MoleculeEntry[] => {
        var lines = csv.split("\n");
        var molecules: MoleculeEntry[] = [];

        for (var i = 6; i < lines.length; i++) {
            
            //Regex to split ony when commas are not inside quotes
            var currentline = lines[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);

            if (currentline[0] !== "") {
                var molecule: MoleculeEntry = {
                    name: extractValueFromCell(currentline[0]),
                    smiles: extractValueFromCell(currentline[1]),
                    meltingPoint: currentline[2] ? Number.parseFloat(currentline[2]) : undefined,
                    enthalpyOfFusion: currentline[3] ? Number.parseFloat(currentline[3]) : undefined,
                    waterSolubility: currentline[4] ? Number.parseFloat(currentline[4]) : undefined,
                    ph: currentline[5] ? Number.parseFloat(currentline[5]) : undefined,
                    logP: currentline[6] ? Number.parseFloat(currentline[6]) : undefined,
                    excelFileName: fileName,
                    excelLine: i+1 || undefined,
                }
                molecules.push(molecule);
            }
        }
        return molecules;
    },[]);

    const handleUpload = useCallback((e:any)=>{
        e.preventDefault();
        try{
            setShowFSL(true)
            setFSLContent(<FullScreenLoader />)

            // if(props.onLoading){
            //     props.onLoading(true);
            // }






            var files = e.target.files, f = files[0];

            console.log(f);

            if(f && f.name){
                var ext = f.name.match(/\.([^\.]+)$/)[1];
                console.log(ext);
                if(ext !== 'xls' && ext !== 'xlsx'){
                    throw new Error("Invalid File");
                }
            }


            var reader = new FileReader();

            reader.onload = function (e: any) {
                debugger;
                var data = e.target.result;
                let readedData = XLSX.read(data, { type: 'binary' });
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];

               // const wsReplaced=replaceComma(ws)

                /* Convert array to json*/
                const dataParse = XLSX.utils.sheet_to_csv(ws);


                const res = convertToMoleculesArray(dataParse, f.name);
                props.loadData(res);

                if(divRef.current){
                    divRef.current.value = "";
                }

                // if(props.onLoading){
                //     props.onLoading(false);
                // }
                setShowFSL(false)
            };


            reader.onerror = function(e:any){
                console.log(e);
                // if(props.onLoading){
                //     props.onLoading(false);
                // }
                setShowFSL(false)
            }

            reader.readAsBinaryString(f)

            // setShowFSL(false)
        }catch(err){
            //TODO pop up fail
            console.log(err);
            setShowFSL(false);
        }
    },[divRef, props, convertToMoleculesArray, setShowFSL, setFSLContent]);


    return(
        <div className="inputFile form-field-disposition-no-title" style={style}>
            <div>
                <input ref={divRef}
                    className="d-none"
                    id={props.name}
                    name={props.name}
                    type="file"
                    onChange={handleUpload}
                    placeholder={props.placeholder}
                    accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                />
                <ButtonSubmit text={props.name || ""} color="btn-dark" onClick={() => { divRef.current?.click() }} />
                {
                    props.error ? <p className="error-text">{props.error}</p> : null
                }
            </div>
        </div>
    )
}
