import { useCallback, useMemo } from 'react';
import './HeaderButton.css'

interface IProps{
    text: string;
    onClick?: (option: string) => void;
    className?: string;
}

export function HeaderButton(props: IProps){

    const cssClasses = useMemo(() => {
    if(props.className){
        return props.className;
    } else {
        return ""
    }

    }, [props.className])

    const onClick = useCallback((e) => {
        if(props.onClick) props.onClick(e);
    },[props])
    
    return(
        <div className={cssClasses} onClick={(e) => onClick(e)}>
            <div className="button-text">{props.text}</div>
        </div>
    );
}
