import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { SplashScreen } from './areas/common/splash-screen/SplashScreen';
import { IdentityRoute} from './Infrastructure/Identity/Components/IdentityRoute';


export function AppIdentityModule() {

    return (
        <Switch>
            <IdentityRoute path="/Identity/SignIn" exec={(svc) => svc.login()} component={<SplashScreen/>} />

            <IdentityRoute path="/Identity/SignUp" exec={(svc) => svc.signUp()} ifAuthenticated={<Redirect to='/Identity/SignIn'/>} component={<SplashScreen/>}/>

            <IdentityRoute path="/Identity/ForgottenPassword" exec={(svc) => svc.forgottenPassword()} component={<SplashScreen/>}/>

            <IdentityRoute path="/Identity/SignOut" exec={(svc) => svc.logout()} component={<SplashScreen/>}/>

        </Switch>
    );
}
