import { useCallback, useEffect, useState, useMemo } from "react";
import { IMatrixBucket } from '../../components/result-matrix/ResultMatrix';
import { useViewport } from '../../Infrastructure/UI/Hooks/useViewPort';
import { IMoleculeResult } from '../../models/result-matrix-element';
import { ResultsPopUp } from '../../components/PopUps/ResultsPopUp';
import { IconButton } from '../../components/IconButton/IconButton';
import { ContainerLayout } from '../../components/Container/ContainerLayout';
import { Table } from '../../components/Table/Table';
import { Accordion } from '../../components/Accordion/Accordion';
import { AccordionRowResults } from '../../components/Accordion/AccordionRowResults';
import { ReactComponent as LeftArrow } from '../../assets/svg/menu-left.svg'
import './results.css'
import { useLocation, useHistory } from 'react-router-dom';
import { SubmissionsService } from '../../services/SubmissionsService';
import { MoleculeResult } from "../../models/api/result-report-respons-dto";
import { ResultReportRequestDTO } from "../../models/api/result-report-request-dto";
import React from "react";


function generateMatrix() {
    let matrix: IMatrixBucket[] = [];
    for(let i = 5; i>=1; i--){
      for(let j = 1; j<=5; j++){
        matrix.push({
          content: [],
          propensity_to_crystallize: i,
          solubility: j
        })
      }
    }
    return matrix;
}


function useQuery() {
    const {search} = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

export function ResultsPage(){
    const {width} = useViewport();
    const query = useQuery();
    const history= useHistory();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const[showResultsPopUp, setShowResultsPopUp] = useState(false);

    const [pageLength, setPageLength] = useState(10);
    const [actualPage, setActualPage] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    const[matrix, setMatrix] = useState<IMatrixBucket[]>(generateMatrix());
    const[bucketContent, setBucketContent] = useState<IMoleculeResult[]>([]);

    const [results, setResults] = useState<IMoleculeResult[]>([]);
    const [allResults, setAllResults] = useState<IMoleculeResult[]>([]);


    const loadResults = useCallback(() => {
        let ids = query.getAll("id");
        let service = new SubmissionsService();
        let request: ResultReportRequestDTO ={
            pageLength: pageLength,
            currentPage: actualPage,
            batchIds: ids
        }
        setIsLoading(true)
        service.getResultsForBatches(request).then(res => {
            let aux = res.results.map((r:MoleculeResult): IMoleculeResult => ({
                batch_id: r.batchId ,
                molecule_id: r.moleculeId,
                name: r.name,
                propensity_to_crystallize: r.propensityToCrystallize,
                propensity_to_crystallize_error: r.propensityToCrystallizeError,
                solubility: r.solubility,
                solubility_error: r.solubilityError,
                batch_description: r.batchDescription
            }));
            setTotalRows(res.total);
            setResults(aux);
            setIsLoading(false);
        });
        let newRequest: ResultReportRequestDTO ={
            batchIds: ids
        }
        service.getResultsForBatches(newRequest).then(res => {
            let results = res.results.map((r:MoleculeResult): IMoleculeResult => ({
                batch_id: r.batchId ,
                molecule_id: r.moleculeId,
                name: r.name,
                propensity_to_crystallize: r.propensityToCrystallize,
                propensity_to_crystallize_error: r.propensityToCrystallizeError,
                solubility: r.solubility,
                solubility_error: r.solubilityError,
                batch_description: r.batchDescription
            }));

            setAllResults(results);
        })
    },[setIsLoading, setTotalRows, setResults, pageLength, actualPage, query]);


    useEffect(() => {
        updateMatrix();
        loadResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actualPage, pageLength])


    useEffect(() => {
        updateMatrix();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allResults]);


    const updateMatrix = useCallback(() => {
        if(allResults){
            let matrixClone = [...generateMatrix()];
            allResults.forEach(mol => {
                if(mol.propensity_to_crystallize_error || mol.solubility_error){
                    return;
                }
                let target = matrixClone.find(b => b.solubility === mol.solubility && b.propensity_to_crystallize === mol.propensity_to_crystallize);
                if(target){
                    target.content.push(mol);
                }
            });
            setMatrix(matrixClone);
        }
    }, [setMatrix, allResults])


    const showMoreDrugs = useCallback((bucketContent: IMoleculeResult[]) => {
        setBucketContent(bucketContent);
        setShowResultsPopUp(true)
    },[setShowResultsPopUp, setBucketContent])


    const columns = useMemo(() => {
        return   [
            {
                key: 'batch_description',
                headerLabel: 'Batch Description',
                isHidden: width < 900,
                sortable: false,
                valueTransform: (value: IMoleculeResult) => <>{value.batch_description || ""}</>
            },
            {
                key: 'name',
                headerLabel: 'Identifier',
                isHidden: width < 900,
                sortable: true,
                valueTransform: (value: IMoleculeResult) => <>{value.name}</>
            },
            {
                key: 'retailer',
                headerLabel: 'Solubility',
                sortable: true,
                valueTransform: (value: IMoleculeResult) => <>{value.solubility_error || value.solubility}</>
            },
            {
                key: 'propensity',
                headerLabel: 'PC',
                sortable: true,
                valueTransform: (value: IMoleculeResult) => <>{value.propensity_to_crystallize_error || value.propensity_to_crystallize}</>
            }

        ];
    },[width])

    return(
        <div className="page results-page">
            <div className="page-content results-page-content">
                <div className="results-left">
                    <div className="results-title">Results</div>

                    <div className="results-separatorHeight" />

                    <div className="results-tooltip">
                        <p>STARMAP® evaluates the processability of compounds in CESS® ranking them in terms of their solubility in supercritical CO2 (on the horizontal axis of the matrix) and propensity to crystallize in CESS® (on the vertical axis of the matrix).</p>
                         <p>The coloring of the cells indicates the compatibility with CESS®, with results in the green cells having the greatest likelihood of success.</p>
                         <p>We have different approaches that can be explored to increase the compatibility with CESS® of the more challenging compounds.</p>
                         <div className="results-separatorHeight" />
                    </div>

                    <div className="matrix-component">
                        <div className="graph-content">

                            <div className="results-popup-container">
                                <ResultsPopUp
                                    showResultsPopUp={showResultsPopUp}
                                    setShowResultsPopUp={() => setShowResultsPopUp(false)}
                                    bucketContent={bucketContent}
                                />
                            </div>

                            <div className="matrix-container">
                            <div className="matrix-watermark-top watermark-top-right">generated by STARMAP ®</div>
                            <div className="matrix-watermark">STARMAP ®</div>
                                {
                                    matrix.map((bucket, idx) =>
                                        <React.Fragment key={idx}>
                                            <div className="square">
                                                <div className="items">
                                                    {
                                                    (bucket.content.length < 4)
                                                    ?
                                                        bucket.content.map((t,id) => (<span key={id} className="span-results">{t.name}</span>))
                                                    :
                                                        <>
                                                            {bucket.content.slice(0,3).map((t,id) => (<span key={id} className="span-results">{t.name}</span>))}
                                                            <span className="span-more pointer" onClick={() => showMoreDrugs(bucket.content)}><div className="matrix-dot"><b>+</b></div></span>
                                                        </>
                                                    }
                                                </div>

                                                <div className="items-icons">
                                                    {(bucket.content.length > 0)
                                                    ?
                                                        <div className="matrix-dot" onClick={() => showMoreDrugs(bucket.content)}>
                                                            <div>{bucket.content.length}</div>
                                                        </div>
                                                    :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                }
                            </div>
                            <div className="yaxis"><div className="axis-label">PROPENSITY TO CRYSTALLIZE</div></div>
                            <div className="yaxis-scale">
                                <div className="scale-unit"><div className="rotate-number"><div>1</div></div></div>
                                <div className="scale-unit"><div className="rotate-number"><div>2</div></div></div>
                                <div className="scale-unit"><div className="rotate-number"><div>3</div></div></div>
                                <div className="scale-unit"><div className="rotate-number"><div>4</div></div></div>
                                <div className="scale-unit"><div className="rotate-number"><div>5</div></div></div>
                            </div>
                            <div className="xaxis">SOLUBILITY</div>
                            <div className="xaxis-scale">
                                <div className="scale-unit">1</div>
                                <div className="scale-unit">2</div>
                                <div className="scale-unit">3</div>
                                <div className="scale-unit">4</div>
                                <div className="scale-unit">5</div>
                            </div>



                        </div>
                    </div>

                    <div>
                        <div className="results-separatorHeight" />
                        <p>Molecules are ranked according to their solubility in supercritical CO2 and propensity to crystallize in CESS®</p>
                        <p>Both solubility and propensity to crystallize can range from 1 (less suited) to 5 (better fit).</p>
                    </div>
                </div>

                <div className="results-separatorWidth"/>

                <div className="results-right">
                    <div className="results-actions">
                        <div className="form-actions">
                            <div className="actions">
                                <IconButton id="button-my-submissions" className="btn-white" icon={<LeftArrow fill="#231F20"/>} text="My Submissions" onClick={() => history.push("/app/submissions")} />
                            </div>
                        </div>
                        <div className="results-separatorHeight" />

                        <div className="results-table-title">Evaluations Results</div>
                        <div className="results-separatorHeight" />
                        <ContainerLayout className="container-layout-tableResutlts">
                        <div className="tableWatermark"/>
                        <div className="matrix-watermark-top watermark-bottom">generated by STARMAP ®</div>


                            <div className="container-tableResults">
                                <Table
                                    isLoading={isLoading}
                                    className="n4mp-table-nopagination"
                                    watermark={true}
                                    dataTotalItems={totalRows}
                                    dataList={results}
                                    columnDefinition={columns}
                                    onCurrentPageChanged={setActualPage}
                                    itemsPage={pageLength}
                                    currentPage={actualPage}
                                    onRowsPerPageChange={setPageLength}
                                    paginationOptions={[
                                        {key:10, label:10},
                                        {key:20, label:20},
                                        {key:50, label:50}
                                    ]}
                                />
                            </div>
                            <div className="container-accordion">
                                <Accordion
                                    isLoading={isLoading}
                                    list={results}
                                    component={(molecule) => <AccordionRowResults molecule={molecule}/>}
                                    currentPage={actualPage}
                                    dataTotalItems={totalRows}
                                    onCurrentPageChanged={setActualPage}
                                    itemsPage={pageLength}
                                    onRowsPerPageChange={setPageLength}
                                    paginationOptions={[
                                        { key: 10, label: 10 },
                                        { key: 20, label: 20 },
                                        { key: 50, label: 50 }
                                    ]}
                                />
                            </div>
                        </ContainerLayout>
                    </div>
                </div>
            </div>

            <div className="results-separatorHeight" />
            <div className="results-footer"><p><b>Note:</b> Computations regarding solubility or crystallinity may in rare cases fail for some molecules. In case this happens, the molecule will not appear in the matrix plot, but all the properties that were successfully computed are listed in the table.</p></div>

        </div>
    );
}
