import './PopUp.css'
import { IconButton } from '../IconButton/IconButton';
import { useHistory } from 'react-router-dom';

interface IProps{
    batchId: string;
    onClose: () => void;
}

export function SubmitPopUp(props: IProps){

    const history = useHistory();

    return(
        <div className="popup-main">
            <div className="popup-title">
               Success!
            </div>
            <div className="popup-separator-height"/>
            <div className="popup-message">
                <div className="color-light">
                    <p>Batch successfully submited.</p>
                    <p>Thank you for submitting a batch for Analysis.</p>
                    <p>Results will be available in the dashboard upon the end of the analysis.</p>
                    <div className="batchId">BatchId: {props.batchId}</div>
                </div>
            </div>
            <div className="popup-separator-height"/>
            <div className="popup-actions">
                <IconButton className="iconbutton btn-white" text="Back to Dashboard" onClick={() => {history.push('/app/submissions'); props.onClose()}} />
                <IconButton className="iconbutton btn-dark" text="Submit Another" onClick={() => window.location.reload()} />
            </div>
        </div>
    );
}