import './App.css';
import {Route, Switch } from 'react-router-dom';

import {AppIdentityModule} from './App.Identity.Module';
import {AppModule} from './App.Module';
import {SplashScreen} from './areas/common/splash-screen/SplashScreen';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { MainBackgroundLayout } from './components/layouts/main-background-layout';
import { LandingPage } from './areas/landing/landing-page';
import { AccountNotValidPage } from './areas/errorPage/AccountNotValidPage';
import PersonalizedErrorPage from './areas/errorPage/PersonalizedErrorPage';



function App() {
  const {inProgress} = useMsal();

  return (
    
        <MainBackgroundLayout>

          {(inProgress !== InteractionStatus.Startup && inProgress !==InteractionStatus.HandleRedirect && inProgress !==InteractionStatus.Login)  ?

              <Switch>
                <Route path="/splash"><SplashScreen/></Route>

                <Route path="/identity"><AppIdentityModule/></Route>

                <Route exact path="/"><LandingPage /></Route>
                <Route path="/app"><AppModule /></Route>
                <Route path={"/accountNotValidated"}><AccountNotValidPage/></Route>
                <Route path="*"><PersonalizedErrorPage/></Route>


              </Switch>

          : <SplashScreen/>}

        </MainBackgroundLayout>
  );
}

export default App;
