import React, { useMemo } from 'react';
import './Input.css';
import { ReactComponent as ClearSVG } from '../../assets/svg/x.svg';


interface IInputProps{
    value?: any;
    placeholder?: string;
    name?: string;
    errorMessage?: string;
    id?: any;
    className?: string;
    type?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
    onFocus?: React.FocusEventHandler<HTMLElement>;
    onBlur?: React.FocusEventHandler<HTMLElement>;
    onClear?: () => void;
}

export function NewInput(props: IInputProps){

    // const [value, setValue] = useState(props.value);

    // useEffect(() => {
    //     if(props.value !== value){
    //         setValue(props.value);
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [props.value])

    const cssClasses = useMemo(() => {
        if(props.className){
            return "n4mp-newinput-" + props.className+ (props.errorMessage ? " error" : "");
        } else {
            return "n4mp-newinput"
        }
    }, [props.className, props.errorMessage])

    return(
        <div className={cssClasses}>
            <input
                className="newInput"
                type={props.type}
                placeholder={props.placeholder}
                name={props.name}
                id={props.id}
                value={props.value}
                onChange={props.onChange}
                onKeyPress={props.onKeyPress} onBlur={props.onBlur} onFocus={props.onFocus}
            />
            {props.onClear && props.value !== "" ?
                <ClearSVG className="clear-svg" onClick={() => {if(props.onClear) props.onClear()}}/>
            :null}
        </div>
    );
}