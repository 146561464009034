declare global {
    interface Window {
        SmilesValidator: {
            validate: (value: string) => boolean
        }
    }
}

export class SmilesValidator{

    static validateSmiles(value: string) : boolean {

        //var mol = RDKit.get_mol(value || "invalid");
        
        //if (!mol) return false;

        if (!window.SmilesValidator.validate(value)) return false;
        return true;
    }

    static checkMultipleSmiles(value: string) : boolean {
        if(value.includes('.')) return true;
        return false;
    }
}