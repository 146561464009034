import './PopUp.css'
import { IconButton } from '../IconButton/IconButton';

interface IProps{
    setShowPopup: (value: boolean) => void;
}

export function SubmissionTableHelpPopUp(props: IProps){
    return(
        <div className="popup-main" >
            <div className="popup-title">Submission Table Help</div>
            <div className="popup-separator-height"/>
            
            <div className="popup-align-left">
                <div className="status-error">
                    <div className="subtitle"><b>Color subtitle</b></div>
                    <div className="subtitle"><div className="line-red" />  <div> &nbsp; Value out of range</div></div>
                    <div className="subtitle"><div className="line-yellow"/><div> &nbsp; Potential repeated molecule *</div></div>
                </div>
            </div>
            <div className="popup-separator-height"/>
            
            <div className="popup-message">* When two molecules have the same values but different identifiers.</div>
            <div className="popup-separator-height"/>

            <div className="popup-actions">
                <IconButton className="iconbutton btn-dark" text="Continue" onClick={() => {props.setShowPopup(false)}} />
            </div>
        </div>
    );
}