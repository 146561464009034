import './PopUp.css'

import { useCallback, useState } from 'react';

import { IconButton } from '../IconButton/IconButton';
import { NewInput } from '../Input/NewInput';

interface IProps{
    setBatchDescription: (value: string) => void;
    succssesSubmitMolecule?: () => void;
    setShowPopup: (value: boolean) => void;
}

export function BatchDescriptionPopUp(props: IProps){
    const[description, setDescription] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    
    const validateForm = useCallback((description: string) => {
        if(!description){
            setErrorMessage("The description field is mandatory!");
            return false;
        }
        else{
            setErrorMessage("");
            return true;
        }   
    },[setErrorMessage]);


    const onSave = useCallback((desc: string) => {
        if(validateForm(desc)){
            props.setBatchDescription(desc);
            props.setShowPopup(false)
        }
    },[validateForm, props]);


    return(
        <div className="popup-main" >
            <div className="popup-title">
               Batch Description
            </div>
            <div className="popup-separator-height"/>
            <div className="popup-message">
                <div className="input-text">
                    <p>Please enter a short name for this submission (max 50 characters).</p>
                    <p>The batch description is stored and shown when viewing your previous submissions.</p>
                    <NewInput type="text" className="identifier-input" placeholder="Batch description" onChange={(e) => setDescription(e.target.value)}/>
                    {errorMessage
                        ? <h5 className="error-message">{errorMessage}</h5>
                        : null
                    }
                </div>
            </div>
            <div className="popup-separator-height"/>
            <div className="popup-actions">
                <IconButton className="iconbutton btn-white" text="Cancel" onClick={() => {props.setShowPopup(false)}} />
                <IconButton className="iconbutton btn-dark" text="Save" onClick={() => {onSave(description)}} />
            </div>
        </div>
    );
}