import { useCallback, useEffect, useState } from 'react';
import { ReactComponent as ArrowDown } from '../../assets/svg/menu-down.svg'
import { ReactComponent as ArrowUp } from '../../assets/svg/menu-up.svg'
import './ComboBox.css'
import { IKeyLabel } from '../Table/Table';

interface IProps{
    optionsList: IKeyLabel[];
    onChange: (rowsPerPage: any) => void;
    selectedOp: number;
    css: string;
}

export function ComboBox(props: IProps){
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [options, setOptions] = useState<IKeyLabel[]>([])
    const [selectedOp, setSelectedOp] = useState<number>()


    const handleSelected = useCallback((op: IKeyLabel) => {
        setSelectedOp(op.label)
        props.onChange(op.label)
    },[setSelectedOp, props])


    useEffect(() => {
        setSelectedOp(props.selectedOp)
        setOptions(props.optionsList)
    },[props])


    return(
        <div className="combo-container">
            <div className="combo">
                <div className={"combo-group" + props.css + (isOpen ? " open" : "")} onClick={() => {if(props.css !== "disable"){ setIsOpen(!isOpen) }else{}}}>
                    <div className="combo-text">
                        {selectedOp}
                    </div>
                    <div className={"combo-icon" + props.css}>
                        {isOpen
                            ? <ArrowUp />
                            : <ArrowDown />
                        }
                    </div>
                </div>
            </div>
            {isOpen
            ?
                <div className="combo-dropdown" onMouseLeave={() => setIsOpen(!isOpen)}>
                    {options.map((op, idx) => {
                        return <div key={idx} className="combo-option" onClick={() =>{handleSelected(op); setIsOpen(!isOpen)}}>{op.label}</div>
                    })}
                </div>
            :
                null
            }
        </div>
    );
}