import { Configuration } from './../configs/configuration';
export interface FileDTO
{
    reference : string,
    id : string,
    fileName : string,
}

export default class FilesDownloadService{

    private filesDirectory : FileDTO[] = [
        {
            reference : "MOLECULES_TEMPLATE",
            id : "c1ea30cc-011b-4f4d-8de2-5743853d7c06",
            fileName : "molecules_template.xlsx",
        }
    ]

    downloadFile(fileReference: string) {

        var fileEntry = this.filesDirectory.find((fileEntry) => fileEntry.reference===fileReference);

        if(!fileEntry) return "";

        return Configuration.StarMapAPIBaseUrl() + `api/Files/${fileEntry.fileName}?id=${fileEntry.id}`;
    }

}