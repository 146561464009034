import React, { useMemo } from 'react';
import './Input.css';


interface IInputProps{
    value?: any;
    placeholder?: string;
    name?: string;
    disable?: boolean;
    id?: any;
    className?: string;
    type?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
    onFocus?: React.FocusEventHandler<HTMLElement>;
    onBlur?: React.FocusEventHandler<HTMLElement>;
}

export function Input(props: IInputProps){

    const cssClasses = useMemo(() => {
        let css = "";
        if(props.className){
            css = "n4mp-input-" + props.className;
        } else {
            css = "n4mp-input"
        }

        if(props.disable){
            css = css + " disabled";
        }

        return css;
    }, [props.className, props.disable])

    return(
        <input
            disabled={props.disable}
            className={cssClasses}
            type={props.type}
            placeholder={props.placeholder}
            name={props.name}
            id={props.id}
            value={props.value}
            onChange={props.onChange}
            onKeyPress={props.onKeyPress} onBlur={props.onBlur} onFocus={props.onFocus}
        />
    );
}