
export interface MoleculeEntry {
    name: string;
    smiles: string;
    meltingPoint?: number;
    enthalpyOfFusion?: number;
    waterSolubility?: number;
    ph?: number;
    logP? : number;

    excelFileName?: string;
    excelLine?: number;
}

export enum Molecule{
Identifier = 1 , Smiles = 2 , MeltingPoint = 3, EnthalpyOfFusion = 4, WaterSolubility = 5, PH = 6, LogP = 7
}
