import { ReactComponent as MenuDown } from  '../../assets/svg/menu-down.svg';
import { ReactComponent as MenuUp} from '../../assets/svg/menu-up.svg'
import './Accordion.css'
import { useState } from 'react';
import { Checkbox } from '../Checkbox/Checkbox';
import { AnalysisSubmissionSummary } from '../../models/domain/AnalisysBatchSummary';

interface IProps{
    submission: AnalysisSubmissionSummary;
    addSelectedSubmission: (value: string) => void;
    removeSelectedSubmission: (value: string) => void;
    selectedSubmissionsIds: string[];
}

export function AccordionRowDashboard(props: IProps){

    const[isOpen, setIsOpen] = useState(false);

    return(
        <div className="accordion-row">
            <div className="accordion-row-close">
                <div className="accordion-title">{props.submission.batchDescription}</div>
                    <div className="accordion-actions">
                        {props.submission.status?.trim()?.toLowerCase() === "completed"
                        ?
                            <>
                                <Checkbox isChecked={props.selectedSubmissionsIds.filter(v => v === props.submission.id).length > 0} onToogle={(isChecked) => {if(isChecked){
                                        props.addSelectedSubmission(props.submission.id);
                                    } else {
                                        props.removeSelectedSubmission(props.submission.id);
                                    }
                                }} />
                            </>
                        :
                            null
                        }
                        {isOpen ?
                            <MenuUp className="accordion-svg" onClick={() => setIsOpen(false)}/>
                        :
                            <MenuDown className="accordion-svg" onClick={() => setIsOpen(true)}/>
                        }
                    </div>
                </div>

                { isOpen ?
                    <>
                        <div className="accordion-separator-height" />
                        <div className="accordion-row-open">
                            <div className="accordion-info">
                                <div className='accordion-info-text'>Submission Date (UTC)</div>
                                <div className='accordion-info-text'>{props.submission.submissionTimeStamp}</div>
                            </div>
                            <div className="accordion-info">
                                <div className='accordion-info-text'>Status</div>
                                <div className='accordion-info-text'>{props.submission.status}</div>
                            </div>
                            <div className="accordion-info">
                                <div className='accordion-info-text'>Last Status Update Time (UTC)</div>
                                <div className='accordion-info-text'>{props.submission.statusLastUpdated}</div>
                            </div>
                        </div>
                    </>
                : null
                }
        </div>
    );
}
