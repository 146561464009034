import './PopUp.css'
import { IconButton } from '../IconButton/IconButton';

interface IProps{
    title: string;
    message: string;
    setShowPopup: (value: boolean) => void;
    onClick: () => void;
}

export function ConfirmPopUp(props: IProps){
    return(
        <div className="popup-main" >
            <div className="popup-title">{props.title}</div>
            <div className="popup-separator-height"/>
            
            <div className="popup-message">{props.message}</div>
            <div className="popup-separator-height"/>
            
            <div className="popup-actions">
                <IconButton className="iconbutton btn-white" text="Cancel" onClick={() => props.setShowPopup(false)} />
                <IconButton className="iconbutton btn-dark" text="Continue" onClick={() => props.onClick()} />
            </div>
        </div>
    );
}