import { useMemo } from 'react';
import { ITableColumn } from '../Table';
import './TableRow.css';
import ReactTooltip from "react-tooltip";

interface ITableRowCellProps<T> {
    item: T,
    columnDefinition: ITableColumn<T>;
}

export function fieldToString(field: any) {
    if(field === undefined || field === null)
        return "";
    return field;
}

export function TableRowCell<T>(props: ITableRowCellProps<T>){
   // const [css, setCss] = useState("n4mp-table-cell");


    // useEffect(() => {
    //     let classes = ["n4mp-table-cell", ...(props.columnDefinition?.cellClassNames || [])];
    //     setCss(classes.join(' '));
    // }, [props.columnDefinition])
    

    const css = useMemo(() => {
        if(props.columnDefinition.cellClassNames){

            if(typeof props.columnDefinition.cellClassNames === 'string'){
                return props.columnDefinition.cellClassNames;
            } else {
                return  props.columnDefinition.cellClassNames(props.item);
            }
        }
        return "n4mp-table-cell";
    }, [props.columnDefinition, props.item]);

    return (
        <td className={css} >
            {(props.columnDefinition.valueTransform && props.columnDefinition.valueTransform(props.item)) || props.item}
        </td>);
}


interface ITableRowProps<T> {
    item: T,
    columnDefinitions: ITableColumn<T>[];
    watermark?: boolean;
}

export function TableRow<T>(props: ITableRowProps<T>){

    return (
        <tr className={props.watermark?'TableRowWatermark':''}>

            {props.columnDefinitions.map((col, cidx) => <TableRowCell key={cidx} item={props.item} columnDefinition={col}/>)}
        </tr>
    );
}
