import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Startup} from './Infrastructure/Startup';
import {SPAApp} from './Infrastructure/SPAApp';
import { BrowserRouter as Router } from 'react-router-dom';


import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-P5Z4NGG'
}

TagManager.initialize(tagManagerArgs)


Startup.Run(() => {
    ReactDOM.render(
      <React.StrictMode>
        <Router>
          <SPAApp>
           <App/>
          </SPAApp>
        </Router>
      </React.StrictMode>,
      document.getElementById('root')
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals(console.log);
});
