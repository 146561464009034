import { useEffect, useState } from 'react';
import './Checkbox.css'

interface ICheckBoxProps {
    onToogle: (isActive: boolean) => void;
    isChecked: boolean;
    text?: string;
}

export function Checkbox(props: ICheckBoxProps){

    const [isActive, setIsActive] = useState(props.isChecked || false);

    useEffect(() => {
        props.onToogle(isActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive])

    return(
        <div>
            <label className="checkbox-container">
                <input type="checkbox" defaultChecked={isActive} onClick={(e) => setIsActive(!isActive)}/>
                <span className="checkmark"></span>
                <span className="text">{props?.text}</span>
            </label>
        </div>
    );
}
